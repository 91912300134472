
const buttoncenter = {
    marginLeft : 'auto',
    marginRight : 'auto',
    display : 'block',
};

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};

export {buttoncenter,formItemLayout}