import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import SuperadminsSagas from './superadmin/saga';
import adminSagas from './admin/saga';
import employeeSagas from './employee/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    SuperadminsSagas(),
    adminSagas(),
    employeeSagas()
    //userSaga()
  ]);
}
