import React, { Component } from 'react';
import LayoutWrapper from '../../../components/utility/layoutWrapper';
import TableDemoStyle from '../../../helpers/tables/demo.style';
import TableWrapper from '../../../helpers/tables/antTable.style';
import AntdSpin from '../../../components/uielements/Spin/index';
import PageHeader from "../../../components/utility/pageHeader";
import {connect} from "react-redux";
import Button from '../../../components/uielements/button';
import {getParametersFromSearchString} from '../../../utils/urlUtils';
import LayoutContentWrapper from "../../../components/utility/layoutWrapper";
import {Switch} from 'antd';
import * as stringUtils from "../../../utils/stringUtils";
import {buttoncenter,formItemLayout} from './crudcss.js';
import authAction from "../../../redux/admin/actions";
import FormItem from "antd/lib/form/FormItem";
import {Form} from "antd";
import LayoutContent from '../../../components/utility/layoutContent';
import {Input} from "antd";
import {injectIntl} from "react-intl";
import createNotification from "../../../components/notification";

const { getEmployeeById , addNewEmployee } = authAction;

class AddOrUpdateEmployee extends Component {

    constructor(props){
        super(props);
        this.urlparams = getParametersFromSearchString(this.props.location.search);
        this.state = { userName : "",fiscalCode: "", firstName : "", lastName: "", email: "", password: "", phoneNumber: "" ,isActivated:false};
        this.handleSwitchFormIsActivated = this.handleSwitchFormIsActivated.bind(this)
        this.handleForm = this.handleForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if(this.urlparams.id !== undefined){
            const {getEmployeeById} = this.props;
            getEmployeeById(this.urlparams);
        }
    }

    componentWillReceiveProps(receivedprops) {
        if (this.props.employee !== receivedprops.employee ) {
            const { email, firstName, fiscalCode, id, idAdmin, isActivated, lastName, library, phoneNumber, userName } = receivedprops.employee;
            this.setState({userName : userName, firstName: firstName, lastName: lastName, email: email, fiscalCode: fiscalCode, phoneNumber: phoneNumber, email: email, phoneNumber: phoneNumber, isActivated : isActivated })
        }
    }

    handleSwitchFormIsActivated(e){
       this.setState({isActivated : e})
       console.log(e)
    }

    handleForm(evt) {
        const { name, value } = evt.target;
        this.setState({[name] : value});
    }

     handleSubmit(){
        const { userName, firstName,lastName, email, password, fiscalCode, phoneNumber, isActivated } = this.state;
        const {addNewEmployee} = this.props;
         let data = {
             userName: userName,
             firstName: firstName,
             lastName: lastName,
             password: password,
             email: email,
             fiscalCode : fiscalCode,
             phoneNumber : phoneNumber,
             isActivated : isActivated,
             ...(this.urlparams.id === undefined ? {role : this.urlparams.role} : {id : this.urlparams.id})
         };

         if(data.userName && data.fiscalCode && data.firstName && data.lastName && data.phoneNumber && data.email  && (data.password || this.urlparams.id ))
            addNewEmployee(data);
         else createNotification("error","I campi presenti sono obbligatori")

     }

    render() {
        const {getFieldDecorator} = this.props.form;
        const log = this.urlparams.id
        const formatMessage = this.props.intl.formatMessage;
        const { userName,firstName,lastName, email, password, fiscalCode, phoneNumber, isActivated } = this.state;
        console.log(this.state.userName)
            return (

                <LayoutContentWrapper>
                    <PageHeader><span>{log ? "Modifica operatore" : "Aggiungi operatore" }</span></PageHeader>
                    <LayoutContent>
                        <Form onSubmit={this.handleSubmit}>
                            <FormItem  label="userName" {...formItemLayout}>
                                {getFieldDecorator('userName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo userName obbligatorio',
                                        },
                                    ],
                                    initialValue : userName
                                })(<Input  name="userName" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Codice fiscale" {...formItemLayout}>
                                {getFieldDecorator('fiscalCode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo codice fiscale obbligatorio',
                                        },
                                    ],
                                    initialValue : fiscalCode
                                })(<Input  name="fiscalCode" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Nome" {...formItemLayout}>
                                {getFieldDecorator('firstName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nome obbligatorio',
                                        },
                                    ],
                                    initialValue : firstName
                                    })(<Input  name="firstName" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Cognome" {...formItemLayout}>
                                {getFieldDecorator('lastName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Cognome obbligatorio',
                                        },
                                    ],
                                    initialValue : lastName
                                })(<Input  name="lastName" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Numero di Telefono" {...formItemLayout}>
                                {getFieldDecorator('phoneNumber', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo numero di telefono obbligatorio',
                                        },
                                    ],
                                    initialValue : phoneNumber
                                })(<Input  name="phoneNumber" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Email" {...formItemLayout}>
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'email obbligatoria',
                                        },
                                    ],
                                    initialValue : email
                                })(<Input  name="email" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Attivato" {...formItemLayout} >
                                <Switch
                                    key={isActivated}
                                    valuePropName="isActivated"
                                    checked={this.state.isActivated}
                                    checkedChildren="V" unCheckedChildren="F"
                                    onChange = {this.handleSwitchFormIsActivated}
                                />
                            </FormItem>

                            {this.urlparams.id === undefined ?
                                <Form.Item {...formItemLayout} label={stringUtils.capitalize(formatMessage({id: "password"}))}>
                                    {getFieldDecorator('password', {
                                        rules: [{
                                            required: true,
                                            message: 'Campo password obbligatorio',
                                        }],
                                        initialValue : password
                                        })(
                                        <Input  type="password" name="password" onChange={this.handleForm} />
                                    )}

                                    </Form.Item>
                                    :
                                    <Form.Item {...formItemLayout} label={"Nuova password"}>
                                        {getFieldDecorator('password', {
                                            rules: [{
                                                required: true,
                                                message: 'Campo password obbligatorio',
                                            }],
                                            initialValue : password
                                        })(
                                        <Input  type="password" name="password" onChange={this.handleForm} />
                                        )}
                                    </Form.Item>
                            }

                            <Form.Item>
                                <Button style = {buttoncenter} type="primary" onClick={this.handleSubmit}>
                                    Salva
                                </Button>
                            </Form.Item>
                        </Form>
                    </LayoutContent>
                </LayoutContentWrapper>

            );
        }

}


export default connect(
    state => ({
        employee : state.Admin.employee
    }),
    { getEmployeeById, addNewEmployee }
)(injectIntl(AddOrUpdateEmployee));