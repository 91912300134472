import { all,call, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken,getRole } from '../../helpers/utility';
import actions from './actions';
import SuperFetch from '../../helpers/superFetch'
import {endpoints} from '../../settings/index'


export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function*(data) {
    const {response, error} = yield call(SuperFetch.post, endpoints.restLogin, data.payload);
    if (response) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: response.access_token,
        roles: response.roles
      })
    } else {
      yield put({
        type: actions.LOGIN_ERROR,
        payload: error
      })
    }
  })
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    if (!payload.doNotSaveInLocalStorage) {
      yield localStorage.setItem('id_token', payload.token);
      yield localStorage.setItem('roles', payload.roles);
      yield put(push('/dashboard'));
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push('/'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    const role = getRole();

    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        roles: role,
        token,
        profile: 'Profile',
        doNotSaveInLocalStorage: true
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
