
const actions = {

    /* GET ALL LIBRARIES BY ADMIN*/
    GETALLLIBRARIESBYADMIN : "GETALLLIBRARIESBYADMIN",
    GETALLLIBRARIESBYADMIN_OK : "GETALLLIBRARIESBYADMIN_OK",
    GETALLLIBRARIESBYADMIN_ERROR : "GETALLLIBRARIESBYADMIN_ERROR",

    getAllLibrariesByAdmin : (data) => ({
        type : actions.GETALLLIBRARIESBYADMIN,
        payload : data
    }),
    /* /GET ALL LIBRARIES BY ADMIN*/

    /* GET ALL EMPLOYEES */
    GETALLEMPLOYEES : "GETALLEMPLOYEES",
    GETALLEMPLOYEES_OK : "GETALLEMPLOYEES_OK",
    GETALLEMPLOYEES_ERROR : "GETALLEMPLOYEES_ERROR",

    getAllEmployees: () => ({
        type : actions.GETALLEMPLOYEES,
    }),
    /* /GET ALL EMPLOYEES */

    /* GET EMPLOYEE BY ID */
    GETEMPLOYEEBYID : "GETEMPLOYEEBYID",
    GETEMPLOYEEBYID_OK : "GETEMPLOYEEBYID_OK",
    GETEMPLOYEEBYID_ERROR : "GETEMPLOYEEBYID_ERROR",

    getEmployeeById: (data) => ({
        type : actions.GETEMPLOYEEBYID,
        payload : data
    }),
    /* /GET EMPLOYEE BY ID */

    /* SAVE EMPLOYEE */
    ADDUPDATEEMPLOYEE : "ADDUPDATEEMPLOYEE",
    ADDUPDATEEMPLOYEE_OK : "ADDUPDATEEMPLOYEE_OK",
    ADDUPDATEEMPLOYEE_ERROR : "ADDUPDATEEMPLOYEE_ERROR",

    addNewEmployee: (data) => ({
        type : actions.ADDUPDATEEMPLOYEE,
        payload : data
    }),
    /* /SAVE EMPLOYEE */

    /* DELETE EMPLOYEE */
    DELETEEMPLOYEE : "DELETEEMPLOYEE",
    DELETEEMPLOYEE_OK : "DELETEEMPLOYEE_OK",
    DELETEEMPLOYEE_ERROR : "DELETEEMPLOYEE_ERROR",

    deleteEmployee: (data) =>({
        type: actions.DELETEEMPLOYEE,
        payload: data
    })
    /* /DELETE EMPLOYEE */

};
export default actions;