import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import {getWrappedForm} from '../../helpers/getWrappedForm';
import AddAdmin from '../Superadmingpage/crudpage/AddOrUpdateAdmin';
import AddSuperAdmin from '../Superadmingpage/crudpage/AddOrUpdateSuperAdmin';
import AddLibrary from '../Superadmingpage/crudpage/AddOrUpdateLibrary';
import AddEmployee from '../Admin/crudpage/AddOrUpdateEmployee';
import AddliteraryGenre from '../Employee/crudpage/AddOrUpdateLiteraryGenre';
import AddShelf from '../Employee/crudpage/AddOrUpdateShelf';
import AddCondition from '../Employee/crudpage/AddOrUpdateCondition';
import AddFormat from '../Employee/crudpage/AddOrUpdateFormat';
import AddBook from '../Employee/crudpage/AddOrUpdateBook';
import AddLoan from '../Employee/crudpage/AddOrUpdateLoan';
import ModifyLibrary from '../Admin/crudpage/ModifyLibrary';



const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'superadminpage',
    component: asyncComponent(() => import('../Superadmingpage/page/Superadmin')),
  },
  {
    path: 'superadminpage/:superAdmin',
    component:  getWrappedForm(AddSuperAdmin)
  },
  {
    path: 'adminpage',
    component: asyncComponent(() => import('../Superadmingpage/page/Admin')),
  },
  {
    path: 'alllibraries',
    component: asyncComponent(() => import('../Superadmingpage/page/AllLibraries')),
  },
  {
    path: 'search',
    component: asyncComponent(() => import('../Superadmingpage/page/Search'))
  },
  {
    path: 'addAdmin/:user',
    component: getWrappedForm(AddAdmin)
  },
  {
    path: 'addLibrary/:library',
    component: getWrappedForm(AddLibrary)
  },

//

  {
    path: 'libraryM',
    component: asyncComponent(() => import('../Admin/page/LibraryMPage')),
  },
  {
    path: 'modifyLibrary/:library',
    component: getWrappedForm(ModifyLibrary)
  },

  {
    path: 'employees',
    component: asyncComponent(() => import('../Admin/page/Employees'))
  },
  {
    path: 'addEmployee/:employee',
    component: getWrappedForm(AddEmployee)
  },
  {
    path: 'searchEmployee',
    component: asyncComponent(() => import('../Admin/page/SearchEmployee'))
  },

  {
    path: 'infoLibrary',
    component: asyncComponent(() => import('../Employee/page/infoLibrary.js'))
  },
  {
    path: 'shelfs',
    component: asyncComponent(() => import('../Employee/page/Shelfs'))
  },
  {
    path: 'AddShelf/:shelf',
    component: getWrappedForm(AddShelf)
  },
  {
    path: 'literaryGenre',
    component: asyncComponent(() => import('../Employee/page/LiteraryGenre'))
  },
  {
    path: 'AddliteraryGenre/:genre',
    component: getWrappedForm(AddliteraryGenre)
  },
  {
    path: 'condition',
    component: asyncComponent(() => import('../Employee/page/Condition'))
  },
  {
    path: 'AddCondition/:condition',
    component: getWrappedForm(AddCondition)
  },
  {
    path: 'format',
    component: asyncComponent(() => import('../Employee/page/Format'))
  },
  {
    path: 'AddFormat/:format',
    component: getWrappedForm(AddFormat)
  },
  {
    path: 'books',
    component: asyncComponent(() => import('../Employee/page/Book'))
  },
  {
    path: 'AddBook/:book',
    component: getWrappedForm(AddBook)
  },
  {
    path: 'Loan',
    component: asyncComponent(() => import('../Employee/page/Loan'))
  },
  {
    path: 'AddLoan/:loan',
    component: getWrappedForm(AddLoan)
  },
  {
    path: 'SearchBook',
    component: asyncComponent(() => import('../Employee/page/SearchBook'))
  },
  {
    path: 'SearchLoan',
    component: asyncComponent(()=>import('../Employee/page/SearchLoan'))
  },

  {
    path: 'blankPage',
    component: asyncComponent(() => import('../blankPage')),
  },


  {
    path: 'authCheck',
    component: asyncComponent(() => import('../AuthCheck')),
  }
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
