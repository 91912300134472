import createNotification from "../components/notification"
import {objectToFormData} from "./objectToFormData"

const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});

const baseHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json'
});

const multiPart = () => ({
  Accept: 'multipart/form-data',
  Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});

const multipartHeader = () => ({
    Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined
})

const fileHeaderNull = () => ({
    'Content-Type': 'multipart/form-data; boundary=0',
    ...localStorage.getItem('id_token') && {Authorization: 'Bearer ' + localStorage.getItem('id_token')},
});

const base = (method, url, data = null, authenticate = false, multipart = false) => {
  let requestConfig = {
    method,

  }
  if (data ) {
    if(multipart ){
        requestConfig.body = objectToFormData(data, {indices: true});
    }else
    requestConfig.body = JSON.stringify(data)
  }

  if(multipart) requestConfig.headers = multipartHeader()
  else if (authenticate) requestConfig.headers = customHeader()
  else requestConfig.headers = baseHeader()



  return fetch(url, requestConfig)
      .then(response => handleResponse(response))
      .catch(error => ({error}))
};

async function handleResponse (response) {
  let errorMessage
  let json
  if (response && response.status && response.status !== 200) {
    switch (response.status) {
      case 401:
        errorMessage = "You are not authorized"
        break
      default:
        errorMessage = "An error has occurred. Error code: " + response.status
        if (response.statusText) {
          errorMessage += "\nError message: " + errorMessage
        }
    }
  } else {
    json = await response.json()
    if (json && json.error) {
      if (json.message) {
        errorMessage = json.message
      } else {
        errorMessage = "An error has occurred"
      }
    }
  }

  if (errorMessage) {
    createNotification('error', errorMessage)
    return {error: errorMessage}
  } else {
    return {response: json}
  }
}



const SuperFetch = {};
['post', 'get',  'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
