const actions = {

    /* GET ALL SUPERADMIN */
    GETALLSUPERADMIN: 'GETALLSUPERADMIN',
    GETALLSUPERADMIN_OK: 'GETALLSUPERADMIN_OK',
    GETALLSUPERADMIN_ERROR: 'GETALLSUPERADMIN_ERROR',

    getAllSuperadmin : (data) =>({
        type : actions.GETALLSUPERADMIN,
        payload : data
    }),
    /* /GET ALL SUPERADMIN */

    /* ADD/UPDATE SUPERADMIN */
    ADDUPDATESUPERADMIN : "ADDUPDATESUPERADMIN",
    ADDUPDATESUPERADMIN_OK : "ADDUPDATESUPERADMIN_OK",
    ADDUPDATESUPERADMIN_ERROR : "ADDUPDATESUPERADMIN_ERROR",

    addNewSuperAdmin : (data) => ({
        type : actions.ADDUPDATESUPERADMIN,
        payload : data
    }),
    /* /ADD/UPDATE SUPERADMIN */

    /* DELETE SUPERADMIN */
    DELETESUPERADMIN : "DELETESUPERADMIN",
    DELETESUPERADMIN_OK : "DELETESUPERADMIN_OK",
    DELETESUPERADMIN_ERROR : "DELETESUPERADMIN_ERROR",

    deleteSuperadmin : (data) => ({
        type : actions.DELETESUPERADMIN,
        payload : data
    }),
    /* /DELETE SUPERADMIN */

    /* GET ALL ADMIN */
    GETALLADMIN: "GETALLADMIN",
    GETALLADMIN_OK: "GETALLADMIN_OK",
    GETALLADMIN_ERROR: "GETALLADMIN_ERROR",

    getAllAdmin : (data) => ({
        type : actions.GETALLADMIN,
        payload : data
    }),
    /* /GET ALL ADMIN */

    /* GET ADMIN BY ID */
    GETADMINBYID : "GETADMINBYID",
    GETADMINBYID_OK : "GETADMINBYID_OK",
    GETADMINBYID_ERROR : "GETADMINBYID_ERROR",

    getAdminById : (data) => ({
        type : actions.GETADMINBYID,
        payload : data
    }),
    /* /GET ADMIN BY ID */

    /* ADD/UPDATE ADMIN */
    ADDUPDATEADMIN : "ADDUPDATEADMIN",
    ADDUPDATEADMIN_OK : "ADDUPDATEADMIN_OK",
    ADDUPDATEADMIN_ERROR : "ADDUPDATEADMIN_ERROR",

    addNewAdmin: (data) => ({
        type : actions.ADDUPDATEADMIN,
        payload : data
    }),
    /* /ADD/UPDATE ADMIN */

    /* DELETE ADMIN */
    DELETEADMIN : "DELETEADMIN",
    DELETEADMIN_OK : "DELETEADMIN_OK",
    DELETEADMIN_ERROR : "DELETEADMIN_ERROR",

    deleteAdmin: (data) => ({
        type: actions.DELETEADMIN,
        payload: data
    }),
    /* /DELETE ADMIN */

    /* GET ALL LIBRARIES */
    GETALLLIBRARIES : "GETALLLIBRARIES",
    GETALLLIBRARIES_OK : "GETALLLIBRARIES_OK",
    GETALLLIBRARIES_ERROR : "GETALLLIBRARIES_ERROR",

    getAllLibraries: () => ({
        type : actions.GETALLLIBRARIES
    }),
    /* /GET ALL LIBRARIES */

    /* GET LIBRARY BY ID */
    GETLIBRARYBYID : "GETLIBRARYBYID",
    GETLIBRARYBYID_OK : "GETLIBRARYBYID_OK",
    GETLIBRARYBYID_ERROR : "GETLIBRARYBYID_ERROR",

    getLibraryById: (data) => ({
        type : actions.GETLIBRARYBYID,
        payload : data
    }),
    /* /GET LIBRARY BY ID */

    /* GET ALL LIBRARY TYPES */
    GETALLLIBRARYTYPE : "GETALLLIBRARYTYPE",
    GETALLLIBRARYTYPE_OK : "GETALLLIBRARYTYPE_OK",
    GETALLLIBRARYTYPE_ERROR : "GETALLLIBRARYTYPE_ERROR",

    getAllLibraryTypes: () => ({
        type : actions.GETALLLIBRARYTYPE
    }),
    /* /GET ALL LIBRARY TYPES */

    /* ADD NEW LIBRARY */
    ADDUPDATELIBRARY : "ADDUPDATELIBRARY",
    ADDUPDATELIBRARY_OK : "ADDUPDATELIBRARY_OK",
    ADDUPDATELIBRARY_ERROR : "ADDUPDATELIBRARY_ERROR",

    addNewLibrary: (data) => ({
        type : actions.ADDUPDATELIBRARY,
        payload : data
    }),
    /* /ADD NEW LIBRARY */

    /* DELETE LIBRARY */
    DELETELIBRARY : "DELETELIBRARY",
    DELETELIBRARY_OK : "DELETELIBRARY_OK",
    DELETELIBRARY_ERROR : "DELETELIBRARY_ERROR",

    deleteLibrary: (data) => ({
        type: actions.DELETELIBRARY,
        payload: data
    }),
    /* /DELETE LIBRARY */

    /* SEARCH */
    SEARCHLIBRARY : "SEARCHLIBRARY",
    SEARCHLIBRARY_OK : "SEARCHLIBRARY_OK",
    SEARCHLIBRARY_ERROR : "SEARCHLIBRARY_ERROR",

    librarySearch: (data) => ({
        type : actions.SEARCHLIBRARY,
        payload : data
    }),
    /* /SEARCH */

    /* GET ALL EMPLOYEES BY LIBRARY */
    GETALLEMPLOYEESBYLIBRARY : "GETALLEMPLOYEESBYLIBRARY",
    GETALLEMPLOYEESBYLIBRARY_OK : "GETALLEMPLOYEESBYLIBRARY_OK",
    GETALLEMPLOYEESBYLIBRARY_ERROR : "GETALLEMPLOYEESBYLIBRARY_ERROR",

    getAllEmployeesByLibrary: (data) => ({
        type : actions.GETALLEMPLOYEESBYLIBRARY,
        payload : data
    }),
    /* /GET ALL EMPLOYEES BY LIBRARY */

};
export default actions;