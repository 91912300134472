import actions from './actions';
import createNotification from "../../components/notification";

const initState = { library: null, allLireraryGenres: null, allShelfs: null, shelf: null, conditions: null, allFormats: null, books: null, getBook: null, BS: null, allLoan: null, getLoan: null };

export default function authReducer(state = initState, action) {
    switch (action.type) {

    /* GET LIBRARY BY EMPLOYEE */
    case actions.GETLIBRARYBYEMPLOYEE_OK:
        return {...state, library: action.library};
    case actions.GETLIBRARYBYEMPLOYEE_ERROR:
        return {...state, library: []};
    /* /GET LIBRARY BY EMPLOYEE */

    /* GET ALL LITERARY GENRES */
    case actions.GETALLLITERARYGENRES_OK:
        return {...state, allLireraryGenres: action.allLireraryGenres};
    case actions.GETALLLITERARYGENRES_ERROR:
        return {...state, allLireraryGenres: []};
    /* /GET ALL LITERARY GENRES */

    /* GET ALL SHELFS */
    case actions.GETALLSHELFS_OK:
        return {...state, allShelfs: action.allShelfs }
    case actions.GETALLSHELFS_ERROR:
        return {...state, allShelfs: []}
    /* /GET ALL SHELFS */

    /* GET SHELF BY ID */
    case actions.GETSHELFBYID_OK:
        return {...state, shelf: action.shelf};
    case actions.GETSHELFBYID_ERROR:
        return {...state, shelf: []};
    /* /GET SHELF BY ID */

    /* GET ALL BOOK CONDITIONS */
    case actions.GETALLCONDITIONS_OK:
        return {...state, conditions: action.conditions};
    case actions.GETALLCONDITIONS_ERROR:
        return {...state, conditions: []};
    /* /GET ALL BOOK CONDITIONS */

    /* GET ALL FORMATS */
    case actions.GETALLFORMATS_OK:
        return {...state, allFormats: action.allFormats};
    case actions.GETALLFORMATS_ERROR:
        return {...state, allFormats: []}
    /* /GET ALL FORMATS */

    /* GET ALL BOOKS BY LIBRARY */
    case actions.GETALLBOOKSBYLIBRARY_OK:
        return {...state, books: action.books};
    case actions.GETALLBOOKSBYLIBRARY_ERROR:
        return {...state, books: []}
    /* /GET ALL BOOKS BY LIBRARY */

    /* DELETE SHELF */
    case actions.DELETESHELF_OK:
        return {...state, allShelfs: [...state.allShelfs.filter((element) => {if(element.id !== action.delete_shelf) return element; return null})]};
    case actions.DELETESHELF_ERROR:
        return {...state}
    /* /DELETE SHELF */

    /* ADD/UPDATE BOOK */
    case actions.ADDUPDATEBOOK_OK:
        return {...state};
    case actions.ADDUPDATEBOOK_ERROR:
        return {...state};
    /* /ADD/UPDATE BOOK */

    /* GET BOOK BY ID */
    case actions.GETBOOKBYID_OK:
        return {...state, getBook: action.getBook};
    case actions.GETBOOKBYID_ERROR:
        return {...state, getBook: []}
    /* /GET BOOK BY ID */

    /* BOOK SEARCH */
    case actions.BOOKSEARCH_OK:
        return {...state, BS: action.BS};
    case actions.BOOKSEARCH_ERROR:
        return {...state, BS: []}
    /* /BOOK SEARCH */

    /* DELETE BOOK */
    case actions.DELETEBOOK_OK:
        return {...state, books : [...state.books.filter((element) => {if(element.id !== action.delete_book) return element; return null})]};
    case actions.DELETEBOOK_ERROR:
        return {...state}
    /* /DELETE BOOK */

    /* ALL LOAN */
    case actions.ALLLOAN_OK:
        return {...state, allLoan: action.allLoan};
    case actions.ALLLOAN_ERROR:
        return {...state, allLoan: []}
    /* /ALL LOAN */

    /* GET LOAN BY ID */
    case actions.GETLOANBYID_OK:
        return {...state, getLoan: action.getLoan}
    case actions.GETLOANBYID_ERROR:
        return {...state, getLoan: []}
    /* /GET LOAN BY ID */

    /* ADD/UPDATE LOAN */
    case actions.ADDUPDATENEWLOAN_OK:
        return {...state};
    case actions.ADDUPDATENEWLOAN_ERROR:
        return {...state};
    /* /ADD/UPDATE LOAN */

    /* DELETE LOAN */
    case actions.DELETELOAN_OK:
        return {...state, allLoan : [...state.allLoan.filter((element) => {if(element.id !== action.delete_loan) return element; return null})]};
    case actions.DELETELOAN_ERROR:
        return {...state}
    /* /DELETE LOAN */

    /* BOOK SEARCH */
    case actions.LOANSEARCH_OK:
        return {...state, LS: action.LS};
    case actions.LOANSEARCH_ERROR:
        return {...state, LS: []}
    /* /BOOK SEARCH */

    default:
        return state;
    }
}