import React from 'react';
import Form from '../components/uielements/form';

export function getWrappedForm(InputComponent) {
    class WrappedComponent extends React.Component {
        render() {
            return <InputComponent {...this.props} />
        }
    }

    return Form.create()(WrappedComponent)
}
