import React, {useRef} from 'react';
import Webcam from 'react-webcam'; //importo la webcam
import {formItemLayout} from './crudcss.js';
import Button from '../../../components/uielements/button';



const videoConstraints = {
    width: 480,
    height: 480,
    facingMode: "user"
};

export function randomFileName(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

function WebcamCapture(props){

    const webcamRef = React.useRef(null)
    const [image, setImage] = React.useState(null)



    const capture = React.useCallback(()=>{
//        const imageSrc = webcamRef.current.getScreenshot(); //mi salvo lo screenshot

        var arr = webcamRef.current.getScreenshot().split(','), mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                    while(n--){
                        u8arr[n] = bstr.charCodeAt(n);
                    }

        const imageSrc = new File([u8arr], randomFileName(10), {type:mime});
        props.saveImg(imageSrc)
    }, [webcamRef]);

    const [enab, setEnab] = React.useState(false)

    const enableWebcam = React.useCallback(()=>{
                    setEnab(!enab)
    });
    const buttoncenter = {
        marginLeft : 'auto',
        marginRight : 'auto',
        marginBottom : "20px",
        display : 'block',
        borderRadius : 99,
    };


    return <div className='webcamCapture'>
        {
            enab ? (
                <div>
                    <Webcam
                        audio={false}
                        height={videoConstraints.height}
                        width={videoConstraints.width}
                        ref = {webcamRef}
                        screenshotFormat= "image/png"
                        videoConstraints={videoConstraints}
                    />

                    <Button style = {buttoncenter} type="primary" onClick={capture}>
                        Scatta
                    </Button>
                    <Button style = {buttoncenter} type="primary" onClick={enableWebcam}  type="">
                        Disattiva webcam
                    </Button>
                </div>

                )
                :
                (
                   <Button type="primary" onClick={enableWebcam} style = {buttoncenter} type="">
                        Attiva webcam
                   </Button>
                )
        }
    </div>;
}



export default WebcamCapture;