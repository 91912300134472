const roleswapsiderbar = (role) =>{
  if(role === 'ROLE_SUPERADMIN'){
   return [
      { key: 'users',
        label: 'sidebar.users',
        leftIcon: 'ion-ios-person',
        children: [
          {
            key: 'superadminpage',
            label: 'sidebar.superadmin',
          },
          {
            key : 'adminpage',
            label : 'sidebar.admin',
          }
        ]
      },
      {
        key: 'alllibraries',
        label: 'sidebar.libraries',
        leftIcon: 'ion-ios-book'
      },
      {
        key: 'search',
        label: 'sidebar.search',
        leftIcon: 'ionicons ion-android-search'
      }

    ];

  } else if (role === 'ROLE_ADMIN'){
        return [
            {
                key:'libraryM',
                label: 'sidebar.libraryM',
                leftIcon: "ion-android-settings"
            },
            {
                key: 'employees',
                label: 'sidebar.employees',
                leftIcon: 'ion-android-people'
            },
            {
                key: 'searchEmployee',
                label: 'sidebar.searchemployee',
                leftIcon: 'ion-android-search'
            }
            ];
  }else if (role === 'ROLE_EMPLOYEE'){
        return [
            {
                key: 'infoLibrary',
                label: 'sidebar.infoLibrary',
                leftIcon: "ion-android-settings"
            },
            {
                key: 'literarygenre',
                label: 'sidebar.literaryGenre',
                leftIcon: 'ion-ios-book'
            },
            {
                key: 'shelfs',
                label: 'sidebar.shelfs',
                leftIcon: 'ion-ios-filing'
            },
            {
                key: 'condition',
                label: 'sidebar.condition',
                leftIcon: 'ion-ios-book'
            },
            {
                key: 'format',
                label: 'sidebar.format',
                leftIcon: 'ion-ios-book'
            },
            {
                key: 'books',
                label: 'sidebar.book',
                leftIcon: 'ion-ios-book'
            },
            {
                key: 'searchBook',
                label: 'sidebar.searchBook',
                leftIcon: 'ionicons ion-android-search'
            },
            {
                key: 'loan',
                label: 'sidebar.loan',
                leftIcon: 'ionicons ion-ios-book'
            },
            {
                key: 'searchLoan',
                label: 'sidebar.searchLoan',
                leftIcon: 'ionicons ion-android-search'
            }

        ]
  }

};

export {roleswapsiderbar}