import React from "react"
import {Icon} from "antd"
import {getToken} from "../helpers/utility.js";
import {endpoints} from '../settings/index.js';
//import SingleImagePicker from "@src/components/digistone/singleImagePicker";
const defaultImage = require("./images/login.jpg")


class GetImageResourceLazy extends React.Component{
    constructor(props){
        super(props)
        this.myRef = React.createRef();
        this.loadLazyImage = this.loadLazyImage.bind(this)
    }

    componentDidMount() {
        if(this.props.fileNameUri){
            this.loadLazyImage();
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(this.props.fileNameUri !== prevProps.fileNameUri){
        }
    }

    loadLazyImage(){
        var self = this
        let requestParameters = "?name=" + this.props.fileNameUri

        if(this.props.requestWidth){
           requestParameters = requestParameters + "&width=" + this.props.requestWidth
        }
        if(this.props.requestHeight){
            requestParameters = requestParameters + "&height=" + this.props.requestHeight
        }
        if(this.props.requestQuality){
            requestParameters = requestParameters + "&quality=" + this.props.requestQuality
        }
        if(this.props.requestDpi){
            requestParameters = requestParameters + "&dpi=" + this.props.requestDpi
        }
        if(this.props.requestFit){
            requestParameters = requestParameters + "&fit=" + this.props.requestFit
        }
        if(this.props.requestFill){
            requestParameters = requestParameters + "&fill=" + this.props.requestFill
        }


        fetch(endpoints.getImageResource + requestParameters, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer '+ getToken().get('idToken'),
                })
            }
        ).then(response => { return response.blob()})
            .then(images => {
                let image = URL.createObjectURL(images)
                if(self.myRef.current){
                    self.myRef.current.src = image
                }
            })
    }


    render() {
        const {
            style = {
                maxWidth:"300px"
            },
            className = "",
        }= this.props
        return (
            <img className={"transition-img "+ className}
                width="70%"
                objectFit="cover"
                height="70%"
                 onError={(e) => {
                     if(this.props.replaceWithDefaultImageUser){
                         e.target.src = defaultImage
                     }
                 }}
                 style={style ? style : null}
                 ref={this.myRef}
                 src = {""}
                 alt="" />
        );
    }
}

export default GetImageResourceLazy
