import React, { Component } from 'react';
import {Input} from "antd";
import LayoutContentWrapper from "../../../components/utility/layoutWrapper";
import PageHeader from "../../../components/utility/pageHeader";
import LayoutContent from '../../../components/utility/layoutContent';
import {Form} from "antd";
import {connect} from "react-redux";
import FormItem from "antd/lib/form/FormItem";
import Button from '../../../components/uielements/button';
import authAction from "../../../redux/superadmin/actions";
import createNotification from "../../../components/notification";
import {getParametersFromSearchString} from '../../../utils/urlUtils';
import {injectIntl} from "react-intl";
import {buttoncenter,formItemLayout} from './crudcss.js'
import * as stringUtils from "../../../utils/stringUtils";
import {Switch} from 'antd';
import AntdSpin from '../../../components/uielements/Spin/index';
import Select, { SelectOption } from '../../../components/uielements/select';

const Option = SelectOption;

const { addNewLibrary , getLibraryById, getAllLibraryTypes, getAllAdmin, getAllEmployees } = authAction;

 class AddorupdateLibrary extends Component {

     constructor(props) {
         super(props);
         this.urlparams = getParametersFromSearchString(this.props.location.search);
         this.handleSubmit = this.handleSubmit.bind(this);
         this.handleForm = this.handleForm.bind(this);
         this.handleSwitchFormIsActivated = this.handleSwitchFormIsActivated.bind(this)
         this.handleSelectTypeForm = this.handleSelectTypeForm.bind(this);
         this.handleSelectlibrarianForm = this.handleSelectlibrarianForm.bind(this);
         this.state = { libraryName : "", street: "", number : "", cap : "", city : "", region : "", country : "", email: "", phoneNumber: "", type:"",librarian:""};
     }

     componentDidMount(){
         const { getAllLibraryTypes } = this.props;
         getAllLibraryTypes();

         const { getAllAdmin } = this.props;
         getAllAdmin();

         /*const { getAllEmployees } = this.props;
         getAllEmployees();*/

         if(this.urlparams.id !== undefined){
         const {getLibraryById} = this.props;
         getLibraryById(this.urlparams);
         }

     }

     componentWillReceiveProps(receivedprops) {
         if (this.props.getLibrary !== receivedprops.getLibrary ) {
            const { libraryName, address, email, phoneNumber,type,librarian } = receivedprops.getLibrary;
            this.setState({libraryName : libraryName, street: address.street, number: address.number, cap:address.cap, city:address.city, region:address.region, country:address.country, email:email, phoneNumber: phoneNumber, type:type.id, librarian:librarian.id })
         }
     }

     handleSelectTypeForm(e){
        this.setState({type : e})
     }

    handleSelectlibrarianForm(e){
        this.setState({librarian : e})
    }

     handleSwitchFormIsActivated(e){
        this.setState({isActivated : e})
     }

     handleForm(evt) {
             const { name, value } = evt.target;
             this.setState({[name] : value});
     }

     handleSubmit(){
        const { libraryName, street, number, cap, city, region, country, email, phoneNumber, type, librarian } = this.state;
        const {addNewLibrary} = this.props;

         let data = {
             libraryName: libraryName,
             street : street,
             number : number,
             cap : cap,
             city : city,
             region : region,
             country : country,
             email: email,
             phoneNumber : phoneNumber,
             idType : type,
             idLibrarian : librarian,
             ...(this.urlparams.id === undefined ? {role : this.urlparams.role} : {id : this.urlparams.id})
         };

         if(data.libraryName && data.street && data.number && data.cap && data.city && data.region && data.country && data.phoneNumber && data.email && data.idType && data.idLibrarian )
            addNewLibrary(data);
         else createNotification("error","I campi presenti sono obbligatori")

     }

    render(){
        const {getFieldDecorator} = this.props.form;
        const { libraryName, street, number, cap, city, region, country, email, phoneNumber, type, librarian } = this.state;
        const formatMessage = this.props.intl.formatMessage
        const log = this.urlparams.id
        const {allTypes} = this.props;
        const { allAdmin } = this.props;
        /*let optionCanile = [];
        allBoxPath.map((item,x)=>{
            optionCanile.push(<Option value={item.idbox}>{item.pathCanileCapannoneBox}</Option>)
        })*/
        if(!allTypes || !allAdmin){
            return <div><AntdSpin/></div>;
        }else{
        let optionLibrary = []
        allTypes.map((item,x)=>{
            optionLibrary.push(<Option value={item.id}>{item.typeName}</Option>)
        })
        let optionLibrarian = []
        allAdmin.map((item,x)=>{
            optionLibrarian.push(<Option value={item.id}>Nome :{item.firstName} Cognome :{item.lastName} Username :{item.userName}</Option>)
        })
            return (
                <LayoutContentWrapper>

                  <PageHeader><span>{log ? 'Modifica Biblioteca' : 'Inserisci Biblioteca'}</span></PageHeader>

                    <LayoutContent>
                        <Form onSubmit={this.handleSubmit}>
                            <FormItem  label="Nome Biblioteca" {...formItemLayout}>
                                {getFieldDecorator('libraryName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nome Biblioteca obbligatorio',
                                        },
                                    ],
                                    initialValue : libraryName
                                })(<Input  name="libraryName" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Numero di Telefono" {...formItemLayout}>
                                {getFieldDecorator('phoneNumber', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo numero di telefono obbligatorio',
                                        },
                                    ],
                                    initialValue : phoneNumber
                                })(<Input  name="phoneNumber" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Email" {...formItemLayout}>
                                {getFieldDecorator('Email', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Email obbligatorio',
                                        },
                                    ],
                                    initialValue : email
                                })(<Input  name="email" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Via" {...formItemLayout}>
                                {getFieldDecorator('street', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo via obbligatorio',
                                        },
                                    ],
                                    initialValue : street
                                })(<Input  name="street" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Numero Civico" {...formItemLayout}>
                               {getFieldDecorator('number', {
                                   rules: [
                                       {
                                           required: true,
                                           message: 'Numero Civico obbligatorio',
                                       },
                                   ],
                                   initialValue : number
                               })(<Input  name="number" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="CAP" {...formItemLayout}>
                                {getFieldDecorator('cap', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'CAP obbligatorio',
                                        },
                                        {
                                            pattern: /^[\d]{5,5}$/,
                                            message: "il cap dev'essere di 5 caratteri ",
                                        },
                                    ],
                                    initialValue : cap
                                })(<Input  name="cap" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Città" {...formItemLayout}>
                                {getFieldDecorator('city', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Città obbligatorio',
                                        },
                                    ],
                                    initialValue : city
                                })(<Input  name="city" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Regione" {...formItemLayout}>
                                {getFieldDecorator('region', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Regione obbligatoria',
                                        },
                                    ],
                                    initialValue : region
                                })(<Input  name="region" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Nazione" {...formItemLayout}>
                                {getFieldDecorator('country', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Nazione obbligatoria',
                                        },
                                    ],
                                    initialValue : country
                                })(<Input  name="country" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Tipo Biblioteca" {...formItemLayout}>
                                {getFieldDecorator('type', {
                                    rules: [
                                    {
                                        required: true,
                                        message: 'Tipo Biblioteca obbligatorio',
                                    },
                                    ]})(
                                <Select name="type" value={type} onChange={this.handleSelectTypeForm} {...formItemLayout} style={{width:"100%"}} allowClear>
                                    {optionLibrary}
                                </Select>)}
                            </FormItem>

                            <FormItem  label="Amministratore" {...formItemLayout}>
                            {getFieldDecorator('librarian', {
                                rules: [
                                {
                                    required: true,
                                    message: 'Amministratore obbligatorio',
                                },
                                ]})(
                                <Select name="librarian" value={librarian} onChange={this.handleSelectlibrarianForm} {...formItemLayout} style={{width:"100%"}} allowClear>
                                    {optionLibrarian}
                                </Select>)}
                            </FormItem>

                           <Form.Item>
                                <Button style = {buttoncenter} type="primary" onClick={this.handleSubmit}>
                                    Salva
                                </Button>
                           </Form.Item>
                        </Form>
                    </LayoutContent>
                </LayoutContentWrapper>
            );
        }
    }
}

export default connect(
    state => ({
        getLibrary : state.Superadmin.getLibrary,
        allTypes : state.Superadmin.allTypes,
        allAdmin : state.Superadmin.allAdmin
        }),
    { addNewLibrary, getLibraryById, getAllLibraryTypes, getAllAdmin, getAllEmployees}
)(injectIntl(AddorupdateLibrary));
/*<Select name="box" value={box} onChange={this.handleSelectBoxForm} {...formItemLayout} style={{width:"100%"}} allowClear>
                                {optionCanile}
                            </Select>*/
/*<Form.Item {...formItemLayout} required={true} label={stringUtils.capitalize(formatMessage({id: "usercanile"}))}>
                            <AutoComplete
                                dataSource={userswithnocanile.map(element => {return {text:element.username,value:element.id}})}
                                style={{ width: 200 }}
                                onSearch={this.autocompleteonchange}
                                onSelect={this.onSelect}
                                value = {currentadmin}
                                allowClear={true}
                                filterOption={(inputValue, option) =>{
                                    return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }}
                            />
                        </Form.Item>*/