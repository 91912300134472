import React, { Component } from 'react';
import {Input, Checkbox} from "antd";
import LayoutContentWrapper from "../../../components/utility/layoutWrapper";
import PageHeader from "../../../components/utility/pageHeader";
import LayoutContent from '../../../components/utility/layoutContent';
import {Form} from "antd";
import {connect} from "react-redux";
import FormItem from "antd/lib/form/FormItem";
import Button from '../../../components/uielements/button';
import authAction from "../../../redux/employee/actions";
import createNotification from "../../../components/notification";
import {getParametersFromSearchString} from '../../../utils/urlUtils';
import {injectIntl} from "react-intl";
import {buttoncenter,formItemLayout} from './crudcss.js'
import * as stringUtils from "../../../utils/stringUtils";
import {Switch, InputNumber} from 'antd';
import LayoutWrapper from '../../../components/utility/layoutWrapper';
import AntdSpin from '../../../components/uielements/Spin/index';
import Select, { SelectOption } from '../../../components/uielements/select';
import './style.css'
import Uploadimages from '../../../helpers/imageupload/uploadImages';
import { DatePicker, Space } from 'antd';
import WebcamCapture from './WebcamCapture.js';
import { Card } from 'antd';
import Bardcodetextfield from '../page/ScannerSearch';
import 'antd/dist/antd.css';
import GetImageResourceLazy from '../../../components/GetImageResourceLazy';
import moment from 'moment'


const { RangePicker } = DatePicker;
const Option = SelectOption;

const { getLoanById, addNewLoan, getAllShelfs, bookSearch } = authAction;

class AddOrUpdateLoan extends Component {

    constructor(props){
        super(props);
        this.urlparams = getParametersFromSearchString(this.props.location.search);
        this.state = { selectedTitle:"", selectedAuthor: "", selectedIsbn:"",title: "", author: "", shelfCode:"", isbn:"", name: "", surname: "", phoneNumber:"", fiscalCode: "", loanStartDate: '', loanEndDate: '', imgsDocuments: [], imageComponent:[], flag:false, idBook: "", returned:false,expired:false} // imgsDocuments contiene i documenti che sono salvati nel db mentre imageComponent contiene i documenti che vengono passati dal componente che fa le foto
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleForm = this.handleForm.bind(this)
//        this.arrayimagestohandle = {'imagesalreadyset' : [], 'imagestoadd' : []};
//        this.liftimageup = this.liftimageup.bind(this)
        this.onChange = this.onChange.bind(this)
        this.saveImg = this.saveImg.bind(this)
        this.removeImg = this.removeImg.bind(this)
        this.removeImgComponent = this.removeImgComponent.bind(this)
        this.saveISBN = this.saveISBN.bind(this)
        this.onSearchTitle = this.onSearchTitle.bind(this)
        this.onSearchAuthor = this.onSearchAuthor.bind(this)
        this.handleSelectShelf = this.handleSelectShelf.bind(this)
        this.removeBook = this.removeBook.bind(this)
        this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this)
        this.checkReturned = this.checkReturned.bind(this)
    }

    componentDidMount() {
        if(this.urlparams.id !== undefined){
            const {getLoanById} = this.props;
            getLoanById(this.urlparams);
        }
        const { bookSearch } = this.props;
        let data = {
            title : null,
            author : null,
            shelfCode : null
        }
                 bookSearch(data);
        const { getAllShelfs } = this.props
            getAllShelfs();


    }

    componentWillReceiveProps(props) {
        if (this.props.getLoan !== props.getLoan ) {
            const { name, surname, phoneNumber, fiscalCode, loanStartDate, loanEndDate, photos, book, expired, returned } = props.getLoan
            this.setState({ name:name, surname:surname, phoneNumber: phoneNumber, fiscalCode:fiscalCode, loanStartDate:moment(loanStartDate), loanEndDate:moment(loanEndDate), imgsDocuments:photos, idBook: book.id, selectedTitle: book.title, selectedAuthor: book.author, selectedIsbn: book.isbn, expired: expired, returned: returned})
        }
    }

    shouldComponentUpdate(nextProp, nextState){
            if(nextState.isbn != null && nextState.isbn != this.state.isbn){
                this.setState({ isbn: nextState.isbn })
                const {  title, author, shelfCode } = this.state;
                const { bookSearch } = this.props;
                let data = {
                    title : title,
                    author : author,
                    shelfCode : shelfCode,
                    isbn : nextState.isbn
                }
    /*            bookSearch(data);*/
            }
            return true
        }

    /* funzioni per la ricerca del libro */

    saveISBN(isbn){
            if(isbn != this.state.isbn){
                let v = isbn.currentTarget.value;
                this.setState({isbn: v})
                const { title, author, shelfCode } = this.state
                const { bookSearch } = this.props
                let data = {
                    author : author,
                    title : title,
                    shelfCode : shelfCode,
                    isbn : v
                }
                bookSearch(data);
            }
        }

    onSearchTitle(e){
            const { value } = e.target;
            const { author, shelfCode } = this.state
            this.setState({ title : value });
            const { bookSearch } = this.props
            let data = {
                title : value,
                author : author,
                shelfCode : shelfCode
            }
            bookSearch(data)
        }

    onSearchAuthor(e){
            const { value } = e.target;
            this.setState({ author : value });
            const { title, shelfCode } = this.state
            const { bookSearch } = this.props
            let data = {
                title : title,
                author : value,
                shelfCode : shelfCode
            }
            bookSearch(data)
        }

    handleSelectShelf(e){
            this.setState({shelf : e})
            const { title, author } = this.state
            const {bookSearch} = this.props
            let data = {
                author : author,
                title : title,
                shelfCode : e
            }
            bookSearch(data)
        }

    prestito(e){
        this.setState({ flag: true, idBook: e.id,selectedTitle: e.title, selectedAuthor: e.author, selectedIsbn: e.isbn })
    }

    removeBook(){
        this.setState({flag: false, idBook: "", selectedTitle:"", selectedAuthor:"", selectedIsbn:""})
    }

    /* funzioni per la ricerca del libro */

    removeImg(x){
        const { imgsDocuments } = this.state
        let arr = imgsDocuments
        const index = x
        if(index>-1){
            arr.splice(index,1);
        }
        this.setState({imgsDocuments: arr})
    }

    saveImg(image){
        this.setState(prevState => ({
          imageComponent: [...prevState.imageComponent, image]
        }))
    }

    removeImgComponent(x){ //questa funzione rimuove le foto dall'array che inserisco dal componente
        const { imageComponent } = this.state
        let arr = imageComponent
        const index = x
        if( index > -1 ){
            arr.splice(index, 1)
        }
        this.setState({ imageComponent : arr })
    }

    removeImg(x){ //questa funzione rimuove le foto dall'array che mi viene passato dal backend
        const { imgsDocuments } = this.state
        let arr = imgsDocuments
        const index = x
        if( index > -1 ){
            arr.splice(index, 1)
        }
        this.setState({ imgsDocuments : arr })
    }

    handleForm(evt){
        const { name, value } = evt.target;
        this.setState({[name] : value})
    }

    onChange(date, dateString) {


    //let start = new Date(date[0])  let end = new Date(date[1])  loanStartDate: new Date(date[0].format()).getTime(), loanEndDate: new Date(date[1].format()).getTime()
    let start = new Date(date[0].format()).getTime()
    let end = new Date(date[1].format()).getTime()


            this.setState({
                loanStartDate: date[0],
                loanEndDate: date[1]

            });

    }

    handleChangePhoneNumber(value){
        if(value.target.value === ""){
            this.setState({phoneNumber: ""})
        }else{
            this.setState({phoneNumber: value.target.value})
        }
    }

    handleSubmit(){
        const { idBook, name, surname, phoneNumber, fiscalCode, imageFile, loanStartDate, loanEndDate, imgsDocuments, imageComponent, expired, returned } = this.state;
        const { addNewLoan } = this.props;

        let data = {
            idBook : idBook,
            Name : name,
            Surname : surname,
            phoneNumber : phoneNumber,
            fiscalCode : fiscalCode,
            loanStartDate : new Date(this.state.loanStartDate.format()).getTime(),
            loanEndDate : new Date(this.state.loanEndDate.format()).getTime(),
            newDocuments : imageComponent,
            savedDocuments : imgsDocuments,
            expired:expired,
            returned: returned,
            ...(this.urlparams.id === undefined ? {role : this.urlparams.role} : {id : this.urlparams.id})
        }
//&& (data.savedDocuments.length > 0 || data.newDocuments.length > 0)
        if( data.idBook && data.Name && data.Surname && data.fiscalCode && data.loanStartDate && data.loanEndDate ){
            addNewLoan(data)
        }else{
            createNotification("error","Campi obbligatori");
        }

    }

    checkReturned(e){
        console.log(e.target.checked)
        this.setState({returned:e.target.checked})
    }


    render() {
        const log = this.urlparams.id;
        const {getFieldDecorator} = this.props.form;
        const { allShelfs } = this.props
        const { BS } = this.props
        const { selectedTitle, selectedAuthor, selectedIsbn, name, surname, phoneNumber, fiscalCode, loanStartDate, loanEndDate, imgsDocuments, imageComponent, idBook, returned } = this.state
        const dateFormat = 'DD/MM/YYYY';
        const searchInput = {
            display: "block"
        }
        const ellipsis = {
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        };

        const checked = {
            border:"1px solid green",
            padding:"20px"
        };

        const notChecked = {
            border:"1px solid red",
            padding:"20px"
        }

        const imgMaxWidth ={
            maxWidth: "300px"
        }


        if(!allShelfs || !BS){
            return <div><AntdSpin/></div>
        }else{
            let optionShelfs = [];
            allShelfs.map((shelf,j)=>{
                optionShelfs.push(<Option value={shelf.shelfCode} key={shelf.id}>Codice Scaffale: {shelf.shelfCode} / Capacità: {shelf.capacity}</Option>)
            })
            return (

                <LayoutContentWrapper>
                    <PageHeader>Inserisci Prestito</PageHeader>
                    <LayoutContent>
                        <Form >
                            {
                                idBook == "" ?
                                    (<div style={{display:"block", paddingBottom:"20px"}}>
                                        <h1>Campi Ricerca</h1>
                                        <div style={{display:"flex", width:"100%", justifyContent:"space-between" }}>
                                            <Input style={{maxWidth:"20%"}} name = "title" className="" placeholder="Titolo Libro" onChange={this.onSearchTitle}/>
                                            <Input style={{maxWidth:"20%"}} name = "author" className="" placeholder="Authore Libro" onChange={this.onSearchAuthor}/>
                                            <Select style={{width:"30%"}} allowClear mode="single"  placeholder="Scaffale" showSearch onChange={(e)=>this.handleSelectShelf(e)}>
                                                {optionShelfs}
                                            </Select>
                                        </div>
                                        <div>
                                            <FormItem style={{marginTop:"30px"}}>
                                                <div id="scanner-container" />
                                                    <div>
                                                    <p style={{ display: "inline-block" }}>Barcode: </p>
                                                    <Input name="isbn" style={{width:"50%"}} onChange={this.saveISBN}/>
                                                    {/*<Bardcodetextfield isbn={this.saveISBN} style={{ display: "inline-block" }}  prova = {"prova"}/>*/}
                                                    </div>
                                            </FormItem>
                                        </div>
                                    </div>)
                                    : null
                            }
                            {
                                idBook == "" ?
                                    (<div style={{display:"flex", flexWrap:"wrap", marginBottom:"20px"}} >
                                        {
                                            BS.map((item,x) =>{
                                                return (
                                                    <Card
                                                        key={item.id}
                                                        hoverable
                                                        style={{ width: 240, minHeight:"250px", marginRight:"10px"}}
                                                    >
                                                        <h2 style={ellipsis}>Titolo</h2>
                                                            <p style={ellipsis}>{item.title}</p>
                                                        <h1 style={ellipsis}>Autore</h1>
                                                            <p style={ellipsis}>{item.author}</p>
                                                        <h1 style={ellipsis}>Shelf code</h1>
                                                            <p style={ellipsis}>{item.shelf.shelfCode}</p>
                                                        <h1 style={ellipsis}>ISBN</h1>
                                                            <p style={ellipsis}>{item.isbn}</p>
                                                        <Button style = {{marginBottom : "20px"}} type="primary" onClick={()=>{this.prestito(item)}}>Prestito
                                                        </Button>
                                                        </Card>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                    : null
                                }

                            <FormItem  label="Libro Prestito" {...formItemLayout} style={ idBook == "" ? notChecked : checked }>
                                <div>
                                    <p>Title: {selectedTitle}</p>
                                    <p>Autore: {selectedAuthor}</p>
                                    <p>Isbn: {selectedIsbn}</p>
                                </div>

                                {
                                    idBook != "" ? (
                                        <div>
                                            <Button style = {{marginTop: "10px", color:"red", borderColor:"red", width:"10%"}} type="dashed" onClick={()=>{this.removeBook()}}>-</Button>
                                        </div>
                                    )
                                    :
                                    null
                                }
                            </FormItem>



                            <FormItem  label="Nome" {...formItemLayout}>
                                {getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Nome obbligatorio',
                                        },
                                    ],
                                    initialValue : name
                                })(<Input  name="name" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Cognome" {...formItemLayout}>
                                {getFieldDecorator('surname', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Cognome obbligatorio',
                                        },
                                    ],
                                    initialValue : surname
                                })(<Input  name="surname" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Codice fiscale/Carta d'identità" {...formItemLayout}>
                                {getFieldDecorator('fiscalCode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo Codice fiscale/Carta d'identità obbligatorio",
                                        },
                                    ],
                                    initialValue : fiscalCode
                                })(<Input  name="fiscalCode" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Numero di telefono" {...formItemLayout}>
                                <Input value={phoneNumber} name="phoneNumber" onChange={this.handleChangePhoneNumber}/>
                            </FormItem>


                            {/*<FormItem  label= "foto documenti" {...formItemLayout}>
                                {getFieldDecorator('foto documenti', {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Foto Documenti obbligatorie",
                                        },
                                    ]
                                })(
                                <>
                                    <WebcamCapture saveImg={this.saveImg} />
                                    <div style={{display:"flex", maxWidth:"100%", flexWrap:"wrap", marginTop:"20px"}}>
                                        {
                                            imgsDocuments.map((item,x)=>{
                                                return <div
                                                        key={item.lastModified}
                                                        style={{ display:"flex", flexDirection:"column", alignItems:"center",maxWidth:"430px", width: "48%", height:"auto", marginRight:"5px", padding:"5px", border:"1px solid grey" }}
                                                       >
                                                            <GetImageResourceLazy fileNameUri={item}/>
                                                            <Button style = {{marginTop: "10px", color:"red", borderColor:"red", width:"50%"}} type="dashed" onClick={()=>{this.removeImgComponent(x)}}>-
                                                            </Button>
                                                       </div>
                                            })
                                        }
                                        {
                                            //mi posso creare una card dove visualizzo le singole immagini
                                            //sotto ogni immagine metto un tastino per eliminarla e vado a togliere
                                            //l'immagine in posizione x dall'array di immagini
                                            imageComponent.map((item,x)=>{

                                                return <div
                                                            style={{ display:"flex", flexDirection:"column", alignItems:"center", maxWidth:"430px", width: "49%", height:"auto", marginRight:"5px", padding:"5px", border:"1px solid grey"}}
                                                       >
                                                            <img src={ typeof item == "string" ? item : URL.createObjectURL(item)} alt="" objectFit="cover" width="70%" height="70%" />
                                                            <Button style = {{marginTop: "10px", color:"red", borderColor:"red", width:"50%"}} type="dashed" onClick={()=>{this.removeImgComponent(x)}}>-
                                                            </Button>
                                                       </div>
                                            })

                                        }
                                    </div>
                                </>)}
                            </FormItem>*/}

                            <FormItem  label="Intervallo tempo prestito" {...formItemLayout}>
                               <RangePicker
                                    onChange={this.onChange}
                                    format={dateFormat}
                                    value={[loanStartDate,loanEndDate]}
                               />
                            </FormItem>

                            <FormItem  label="Libro restituito" {...formItemLayout}>
                                <Checkbox checked={returned} onChange={this.checkReturned}> </Checkbox>
                            </FormItem>

                            <Form.Item>
                                <Button style = {buttoncenter} type="primary" onClick={this.handleSubmit}>
                                    Salva
                                </Button>
                            </Form.Item>




                        </Form>
                    </LayoutContent>

                </LayoutContentWrapper>

            );}
    }

}


export default connect(
    state => ({
        getLoan : state.Employee.getLoan,
        allShelfs : state.Employee.allShelfs,
        BS: state.Employee.BS
    }),
    { getLoanById, addNewLoan, getAllShelfs, bookSearch }
)(AddOrUpdateLoan);

