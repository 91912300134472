
export function getParametersFromSearchString(searchString) {
    if (searchString.startsWith("?")) searchString = searchString.substring(1, searchString.length)
    let searchParameters = {}
    let parts = searchString.split("&")
    parts.forEach(part => {
        let partElements = part.split("=")
        if (partElements.length === 2) {
            searchParameters[partElements[0]] = partElements[1]
        }
    })
    return searchParameters
}


export function convertObjectToQueryString(obj) {
    if (obj) {
        let queryStringParts = []
        Object.keys(obj).forEach(key => queryStringParts.push(key + "=" + obj[key]))
        return queryStringParts.join('&')
    }
    return ""
}