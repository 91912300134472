import React, { Component } from 'react';
import Spin from './spin.style';

export default class AntdSpin extends Component {
  state = { loading: false };
  toggle = value => {
    this.setState({ loading: value });
  };
  render() {

    const style = {
      textAlign: 'center',
      background: '#f1f3f6',
      padding: '30px 50px',
      width: '100%'
    };


    return (
                <div style={style}>
                  <Spin />
                </div>
    );
  }
}
