import Auth from './auth/reducer';
import App from './app/reducer';
import Superadmin from './superadmin/reducer';
import Admin from './admin/reducer';
import Employee from './employee/reducer';

export default {
  Auth,
  App,
  Superadmin,
  Admin,
  Employee
  //user
};
