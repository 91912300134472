import React from 'react';
import 'antd/dist/antd.css';
import './uploadimages.css';
import { Upload, Icon, Modal } from 'antd';
import {urlimages} from '../../settings/index'

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class UploadImages extends React.Component {
    constructor(props){
        super(props);
        this.imagesalreadyset = this.props.imageFile.map(element => element);
        this.imagestoadd =[];
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            previewVisible: false,
            myflag : true,
            previewImage: '',
            fileList: this.props.imageFile.map((element,index) => ({
                    uid: index,
                    key : index,
                    url: urlimages+element
                })),
        };
    }
    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };
    handleChange = ( fileList ) => {

         if(fileList.file.status === 'uploading' && fileList.file.myflag !== true)
        {   fileList.fileList[fileList.fileList.length-1].status = 'done'
            this.setState({ fileList : fileList.fileList });
            this.imagestoadd.push(fileList.file.originFileObj);
            this.props.liftstate(this.imagesalreadyset,this.imagestoadd);

        }else
            if (fileList.file.status === 'removed'){
                if(fileList.file.originFileObj instanceof File){
                    this.imagestoadd = this.imagestoadd.filter((element) => {if(element !== fileList.file.originFileObj) return element; return null});
                    this.props.liftstate(this.imagesalreadyset,this.imagestoadd);
                }else{
                    this.imagesalreadyset = this.imagesalreadyset.filter((element) => {if(urlimages+element !== fileList.file.url) return element; return null});
                    this.props.liftstate(this.imagesalreadyset,this.imagestoadd);
                }
                this.setState({ fileList :fileList.fileList });
         }
            else if(fileList.file.error){
                fileList.fileList.forEach(element => element.status = 'done');
                this.setState({ fileList :fileList.fileList });
            }

    };

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (   <div>
                    <Upload
                    accept= {".jpg,.png"}
                    multiple={true}
                    listType={"picture-card"}
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                >
                    {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="preview" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

