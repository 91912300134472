import React, { Component } from 'react';
import {Input} from "antd";
import LayoutContentWrapper from "../../../components/utility/layoutWrapper";
import PageHeader from "../../../components/utility/pageHeader";
import LayoutContent from '../../../components/utility/layoutContent';
import {Form} from "antd";
import {connect} from "react-redux";
import FormItem from "antd/lib/form/FormItem";
import Button from '../../../components/uielements/button';
import authAction from "../../../redux/superadmin/actions";
import createNotification from "../../../components/notification";
import {getParametersFromSearchString} from '../../../utils/urlUtils'
import {injectIntl} from "react-intl";
import {buttoncenter,formItemLayout} from './crudcss.js'
import * as stringUtils from "../../../utils/stringUtils";
import {Switch} from 'antd';



const { addNewSuperAdmin , getAdminById } = authAction;

 class AddorupdateSuperAdmin extends Component {

     constructor(props) {
         super(props);
         this.urlparams = getParametersFromSearchString(this.props.location.search);
         this.handleSubmit = this.handleSubmit.bind(this);
         this.handleForm = this.handleForm.bind(this);
         this.handleSwitchFormIsActivated = this.handleSwitchFormIsActivated.bind(this)
         this.state = { userName : "",fiscalCode: "", firstName : "", lastName: "", email: "", password: "", phoneNumber: "" ,isActivated:false};
     }

     componentDidMount(){
         if(this.urlparams.id !== undefined){
         const {getAdminById} = this.props;
         getAdminById(this.urlparams);
         }

     }

     componentWillReceiveProps(receivedprops) {
         if (this.props.getAdmin !== receivedprops.getAdmin ) {
            const { userName, firstName, lastName, email, fiscalCode, phoneNumber, isActivated } = receivedprops.getAdmin;
            this.setState({userName : userName, firstName: firstName, lastName: lastName ,email:email, fiscalCode: fiscalCode, phoneNumber: phoneNumber, isActivated: isActivated })
         }
     }

     handleSwitchFormIsActivated(e){
        this.setState({isActivated : e})
     }

     handleForm(evt) {
             const { name, value } = evt.target;
             this.setState({[name] : value});
     }

     handleSubmit(){
        const { userName,firstName,lastName, email, password, fiscalCode, phoneNumber, isActivated } = this.state;
        const {addNewSuperAdmin} = this.props;
         let data = {
             userName: userName,
             firstName: firstName,
             lastName: lastName,
             password: password,
             email: email,
             fiscalCode : fiscalCode,
             phoneNumber : phoneNumber,
             isActivated : isActivated,
             ...(this.urlparams.id === undefined ? {role : this.urlparams.role} : {id : this.urlparams.id})
         };

         if(data.userName && data.fiscalCode && data.firstName && data.lastName && data.phoneNumber && data.email  && (data.password || this.urlparams.id ))
            addNewSuperAdmin(data);
         else createNotification("error","I campi presenti sono obbligatori")

     }

    render(){
        const {getFieldDecorator} = this.props.form;
        const { userName,firstName,lastName, email, password, fiscalCode, phoneNumber, isActivated } = this.state;
        const formatMessage = this.props.intl.formatMessage
        const log = this.urlparams.id
        return (
            <LayoutContentWrapper>

              <PageHeader><span>{log ? 'Modifica SuperAdmin' : 'Inserisci SuperAdmin'}</span></PageHeader>

                <LayoutContent>
                    <Form onSubmit={this.handleSubmit}>
                        <FormItem  label="userName" {...formItemLayout}>
                            {getFieldDecorator('userName', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo userName obbligatorio',
                                    },
                                ],
                                initialValue : userName
                            })(<Input  name="userName" onChange={this.handleForm}/>)}
                        </FormItem>

                        <FormItem  label="Codice fiscale" {...formItemLayout}>
                            {getFieldDecorator('fiscalCode', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo codice fiscale obbligatorio',
                                    },
                                ],
                                initialValue : fiscalCode
                            })(<Input name="fiscalCode" max="16" min="16" onChange={this.handleForm}/>)}
                        </FormItem>

                        <FormItem  label="Nome" {...formItemLayout}>
                            {getFieldDecorator('FirstName', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Nome obbligatorio',
                                    },
                                ],
                                initialValue : firstName
                            })(<Input  name="firstName" onChange={this.handleForm}/>)}
                        </FormItem>

                        <FormItem  label="Cognome" {...formItemLayout}>
                            {getFieldDecorator('LastName', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo Cognome obbligatorio',
                                    },
                                ],
                                initialValue : lastName
                            })(<Input  name="lastName" onChange={this.handleForm}/>)}
                        </FormItem>

                        <FormItem  label="Numero di Telefono" {...formItemLayout}>
                            {getFieldDecorator('phoneNumber', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo numero di telefono obbligatorio',
                                    },
                                ],
                                initialValue : phoneNumber
                            })(<Input  name="phoneNumber" onChange={this.handleForm}/>)}
                        </FormItem>

                        <FormItem  label="Email" {...formItemLayout}>
                            {getFieldDecorator('Email', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo Email obbligatorio',
                                    },
                                ],
                                initialValue : email
                            })(<Input  name="email" onChange={this.handleForm}/>)}
                        </FormItem>

                        <FormItem  label="Attivato" {...formItemLayout} >
                             <Switch
                                key={isActivated}
                                valuePropName="isActivated"
                                checked={this.state.isActivated}
                                checkedChildren="V" unCheckedChildren="F"
                                onChange = {this.handleSwitchFormIsActivated}
                             />
                        </FormItem>

                        {this.urlparams.id === undefined ?
                            <Form.Item {...formItemLayout} label={stringUtils.capitalize(formatMessage({id: "password"}))}>
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: true,
                                        message: 'Campo password obbligatorio',
                                    }],
                                    initialValue : password
                                })(
                                    <Input  type="password" name="password" onChange={this.handleForm} />
                                )}

                            </Form.Item>
                            :
                            <Form.Item {...formItemLayout} label={"Nuova password"}>
                               {getFieldDecorator('password', {
                                    rules: [{
                                        required: true,
                                        message: 'Campo password obbligatorio',
                                    }],
                                    initialValue : password
                               })(
                                    <Input  type="password" name="password" onChange={this.handleForm} />
                               )}
                            </Form.Item>
                        }

                        <Form.Item>
                            <Button style = {buttoncenter} type="primary" onClick={this.handleSubmit}>
                                Salva
                            </Button>
                        </Form.Item>
                    </Form>
                </LayoutContent>
            </LayoutContentWrapper>
        );
    }
}

export default connect(
    state => ({
        getAdmin : state.Superadmin.getAdmin,
        }),
    { addNewSuperAdmin , getAdminById }
)(injectIntl(AddorupdateSuperAdmin));
