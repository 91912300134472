
const buttoncenter = {
    marginLeft : 'auto',
    marginRight : 'auto',
    display : 'block',
    borderRadius : 99,
};



const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
    },
};

const formSwitcherLayout = {
    borderRadius : 99,
}

export {buttoncenter,formItemLayout,formSwitcherLayout}