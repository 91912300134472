import React, { Component } from 'react';
import LayoutWrapper from '../../../components/utility/layoutWrapper';
import TableDemoStyle from '../../../helpers/tables/demo.style';
import TableWrapper from '../../../helpers/tables/antTable.style';
import AntdSpin from '../../../components/uielements/Spin/index';
import PageHeader from "../../../components/utility/pageHeader";
import {connect} from "react-redux";
import authAction from "../../../redux/employee/actions"
import {roles} from '../../../settings/index'
import Button from '../../../components/uielements/button';
import {getParametersFromSearchString} from '../../../utils/urlUtils';
import {injectIntl} from "react-intl";
import LayoutContentWrapper from "../../../components/utility/layoutWrapper";
import LayoutContent from '../../../components/utility/layoutContent';
import {Form} from "antd";
import FormItem from "antd/lib/form/FormItem";
import {buttoncenter,formItemLayout} from './crudcss.js';
import {Input} from "antd";
import Select, { SelectOption } from '../../../components/uielements/select';
import { InputNumber } from 'antd';
import createNotification from "../../../components/notification";


const Option = SelectOption;

const { getShelfById, addNewShelf, getAllLiteraryGenres } = authAction;

class InfoLibrary extends Component {

    constructor(props){
        super(props);
        this.urlparams = getParametersFromSearchString(this.props.location.search);
        this.state = { floor:"", literaryGenre:"", capacity:"", shelfCode:"" }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectLiteraryGenre = this.handleSelectLiteraryGenre.bind(this);
        this.onChangeCapacity = this.onChangeCapacity.bind(this)
        this.handleFloorForm = this.handleFloorForm.bind(this)
        this.handleCodeForm = this.handleCodeForm.bind(this)
    }

    componentDidMount() {
        if(this.urlparams.id !== undefined){
            const { getShelfById } = this.props;
            getShelfById(this.urlparams);
        }

        const { getAllLiteraryGenres } = this.props;
        getAllLiteraryGenres();
    }

    componentWillReceiveProps(props){
        if(this.props.shelf !== props.shelf){
            const { capacity, floor, library, literaryGenre, shelfCode } = props.shelf;
            this.setState({ floor: floor, literaryGenre: literaryGenre.id, capacity: capacity, shelfCode: shelfCode })
        }
    }

    handleSelectLiteraryGenre(e){
        this.setState({literaryGenre : e})
    }


    onChangeCapacity(e){
        const re = /^[0-9\b]+$/;
        if(re.test(e)){
            this.setState({capacity : e})
        }
    }

    handleCodeForm(e){
        const { name, value } = e.target;
        let a = e.target.value
        let l = a.toLowerCase()
        l = l.replace(/\s+/g, '');
        this.setState({shelfCode : l})
    }

    handleFloorForm(e){
        const { name, value } = e.target;
        let a = e.target.value
        let l = a.toLowerCase()
        l = l.replace(/\s+/g, '');

        this.setState({floor : l})
    }

    handleSubmit(){
        const { floor, literaryGenre, capacity, shelfCode } = this.state
        const { addNewShelf } = this.props


        let data = {
            floor : floor,
            idLiteraryGenre : literaryGenre,
            capacity : capacity,
            shelfCode : shelfCode,
            ...(this.urlparams.id === undefined ? {role : this.urlparams.role} : {id : this.urlparams.id})
        }
        if(data.floor && data.idLiteraryGenre && data.capacity && data.shelfCode)
            addNewShelf(data);
        else createNotification("error","i campi presenti sono obbligatori")

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const { floor, literaryGenre, capacity, shelfCode } = this.state;
        const log = this.urlparams.id
        const { allLireraryGenres } = this.props;

        if(!allLireraryGenres){
            return <div><AntdSpin></AntdSpin></div>
        }else{
            let optionLiteraryGenre = []
            allLireraryGenres.map((lit,i)=>{
                optionLiteraryGenre.push(<Option value={lit.id} key={lit.id}>Nome Genere: {lit.genre}/ Codice Genere: {lit.genreCode}</Option>)
            })
            //const formatMessage = this.props.intl.formatMessage;
            return (

                <LayoutContentWrapper>
                    <PageHeader>{log ? "Modifica Scaffale" : "Aggiungi Scaffale"}</PageHeader>
                    <LayoutContent>
                        <Form onSubmit={this.handleSubmit}>
                            <FormItem  label="Piano Scaffale" {...formItemLayout}>
                                {getFieldDecorator('floor', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Piano scaffale obbligatorio',
                                        },
                                    ],
                                    initialValue : floor
                                })(<Input  name="floor" onChange={this.handleFloorForm}/>)}
                            </FormItem>
                            <FormItem required label="Genere Letterario" {...formItemLayout}>
                                <Select value={literaryGenre} mode="single" style={{ width: '100%' }} placeholder="Genere" showSearch onChange={(e)=>this.handleSelectLiteraryGenre(e)}>
                                    {optionLiteraryGenre}
                                </Select>
                            </FormItem>
                            <FormItem required label="Capacità Scaffale" {...formItemLayout}>
                                <InputNumber keyboard={false} min={1} value={capacity} defaultValue={capacity} onChange={this.onChangeCapacity} />
                            </FormItem>
                            <FormItem  label="Codice scaffale" {...formItemLayout}>
                                {getFieldDecorator('shelfCode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Piano scaffale obbligatorio',
                                        },
                                    ],
                                    initialValue : shelfCode
                                })(<Input  name="shelfCode" onChange={this.handleCodeForm}/>)}
                            </FormItem>
                            <Form.Item>
                                <Button style = {buttoncenter} type="primary" onClick={this.handleSubmit}>
                                    Salva
                                </Button>
                            </Form.Item>
                        </Form>
                    </LayoutContent>
                </LayoutContentWrapper>

            );

        }

        }

}


export default connect(
    state => ({
        shelf: state.Employee.shelf,
        allLireraryGenres: state.Employee.allLireraryGenres
    }),
    { getShelfById, addNewShelf, getAllLiteraryGenres }
)(InfoLibrary);