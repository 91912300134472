import React, { Component } from 'react';
import LayoutWrapper from '../../../components/utility/layoutWrapper';
import TableDemoStyle from '../../../helpers/tables/demo.style';
import TableWrapper from '../../../helpers/tables/antTable.style';
import AntdSpin from '../../../components/uielements/Spin/index';
import PageHeader from "../../../components/utility/pageHeader";
import {connect} from "react-redux";
import authAction from "../../../redux/superadmin/actions"
import {roles} from '../../../settings/index'
import Button from '../../../components/uielements/button';

//const { getalladmin } = authAction;

class AddOrUpdateFormat extends Component {

    componentDidMount() {


    }


    render() {

            return (

                <LayoutWrapper>
                    <PageHeader>Info Biblioteca</PageHeader>

                </LayoutWrapper>

            );
        }

}


export default connect(
    state => ({
    }),

)(AddOrUpdateFormat);