import React, { Component } from 'react';
import {Input} from "antd";
import LayoutContentWrapper from "../../../components/utility/layoutWrapper";
import PageHeader from "../../../components/utility/pageHeader";
import LayoutContent from '../../../components/utility/layoutContent';
import {Form} from "antd";
import {connect} from "react-redux";
import FormItem from "antd/lib/form/FormItem";
import Button from '../../../components/uielements/button';
import authAction from "../../../redux/employee/actions";
import createNotification from "../../../components/notification";
import {getParametersFromSearchString} from '../../../utils/urlUtils';
import {injectIntl} from "react-intl";
import {buttoncenter,formItemLayout} from './crudcss.js'
import * as stringUtils from "../../../utils/stringUtils";
import {Switch, InputNumber} from 'antd';
import LayoutWrapper from '../../../components/utility/layoutWrapper';
import AntdSpin from '../../../components/uielements/Spin/index';
import Select, { SelectOption } from '../../../components/uielements/select';
import Bardcodetextfield from './Scanner';
import './style.css'
import Uploadimages from '../../../helpers/imageupload/uploadImages';
import WebcamCaptureBook from './WebcanCaptureBook.js';
import GetImageResourceLazy from '../../../components/GetImageResourceLazy';

const Option = SelectOption;

const { getBookById, getAllShelfs, getAllConditions, getAllFormats, addNewBook } = authAction;

class AddOrUpdateBook extends Component {

    constructor(props){
        super(props);
        this.urlparams = getParametersFromSearchString(this.props.location.search);
        this.state = { title: "", author: "", price: 0.00, numberOfCopy: 1, specialAttributes: "", shelf: "", condition: "", format: "", category: "", isbn:"", imageFile: [], imageComponent: [] }
        this.onChangePrice = this.onChangePrice.bind(this)
        this.onChangeNofC = this.onChangeNofC.bind(this)
        this.handleSelectFormat = this.handleSelectFormat.bind(this)
        this.handleSelectCondition = this.handleSelectCondition.bind(this)
        this.handleSelectShelf = this.handleSelectShelf.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleForm = this.handleForm.bind(this)
        this.saveISBN = this.saveISBN.bind(this)
        this.arrayimagestohandle = {'imagesalreadyset' : [], 'imagestoadd' : []};
        this.saveImg = this.saveImg.bind(this)
        this.removeImg = this.removeImg.bind(this)
        this.removeImgComponent = this.removeImgComponent.bind(this)
        this.handleChangeIsbn = this.handleChangeIsbn.bind(this)
    }

    componentDidMount() {
        if(this.urlparams.id !== undefined){
            const {getBookById} = this.props;
            getBookById(this.urlparams);
        }

        const { getAllShelfs } = this.props;
        getAllShelfs();

        const { getAllConditions } = this.props;
        getAllConditions();

        const { getAllFormats } = this.props;
        getAllFormats();
    }

    componentWillReceiveProps(props) {
        if (this.props.getBook !== props.getBook ) {
            const { title, author, numberOfCopy, price, specialAttributes, shelf, condition, format, photos, isbn} = props.getBook
           /* if(imageFile)
                this.arrayimagestohandle.imagesalreadyset = imageFile;*/
            if(format){
                this.setState({ imageFile: photos, title: title, author: author, price: price , numberOfCopy: numberOfCopy, specialAttributes: specialAttributes, shelf: shelf.id, condition: condition.id, format: format.id, isbn: isbn})
            }else{
                this.setState({ imageFile: photos, title: title, author: author, price: price , numberOfCopy: numberOfCopy, specialAttributes: specialAttributes, shelf: shelf.id, condition: condition.id, isbn: isbn})
            }
        }
    }

    removeImg(x){
        const { imageFile } = this.state
        let arr = imageFile
        const index = x
        if(index>-1){
            arr.splice(index,1);
        }
        this.setState({imageFile: arr})
    }

    removeImgComponent(x){
        const { imageComponent } = this.state
        let arr = imageComponent
        const index = x
        if(index>-1){
            arr.splice(index,1)
        }
        this.setState({imageComponent: arr})
    }

    saveImg(image){
//        this.setState(prevState => ({
//          imageFile: [...prevState.imageFile, image]
//        }))
        this.setState(prevState => ({
                  imageComponent: [...prevState.imageComponent, image]
                }))
    }

    handleForm(evt){
        const { name, value } = evt.target;
        this.setState({[name] : value})
    }

    handleChangeIsbn(value){
        console.log(value.target.value)
        if(value.target.value === ""){
            this.setState({isbn : ""})
        }else{
            this.setState({isbn : value.target.value})
        }

    }

    onChangeNofC(e){
        const re = /^[0-9\b]+$/;
        if(re.test(e)){
            this.setState({ numberOfCopy : e })
        }
    }

    onChangePrice(value) {
        this.setState({price : value})
    }

    handleSelectFormat(e){
            this.setState({format : e})
        }

    handleSelectCondition(e){
            this.setState({condition : e})
        }

    handleSelectShelf(e){
            this.setState({shelf : e})
        }
    saveISBN(isbn){
        this.setState({isbn: isbn})
    }

    handleSubmit(){
        const { title, author, price, numberOfCopy, specialAttributes, shelf, condition, format, category, isbn, imageFile, imageComponent } = this.state;
        const { addNewBook } = this.props;

        /*var imageFilepost = new FormData();
        if(this.arrayimagestohandle.imagestoadd) {
            for (const file of this.arrayimagestohandle.imagestoadd) {
                imageFilepost.append('imageFile', file, file.name);
            }
        }*/

        let data = {
            title : title,
            author : author,
            price : price,
            numberOfCopy : numberOfCopy,
            specialAttributes : specialAttributes,
            idShelf : shelf,
            idCondition : condition,
            idFormat : format,
            idCategory : category,
            isbn : isbn,
            savedPhotos : imageFile,
            newPhotos : imageComponent,
//            imageFile: this.arrayimagestohandle.imagesalreadyset.length>0 ? this.arrayimagestohandle.imagesalreadyset : [], //
            ...(this.urlparams.id === undefined ? {role : this.urlparams.role} : {id : this.urlparams.id})
        }
        if(data.title && data.author && data.idShelf && data.idCondition  && data.isbn  ){
            addNewBook(data);
        }else{
            createNotification("error","Campi obbligatori");
        }
    }


    render() {
        const log = this.urlparams.id;
        const {getFieldDecorator} = this.props.form;
        const { isbn, results, title, author, price, numberOfCopy, specialAttributes, shelf, condition, format, category, imageFile, imageComponent } = this.state;
        //const formatMessage = this.props.intl.formatMessage
        const { allShelfs } = this.props;
        const { conditions } = this.props;
        const { allFormats } = this.props;


        if(!allShelfs || !conditions || !allFormats  ){

            return <div><AntdSpin></AntdSpin></div>
        }else{
            let optionFormat = [];
            allFormats.map((format,i)=>{
                optionFormat.push(<Option value={format.id} key={format.id}>Nome Formato: {format.formatName}/ Dimensioni: {format.formatDimension}</Option>)
            })

            let optionCondition = [];
            conditions.map((condition,x)=>{
                optionCondition.push(<Option value={condition.id} key={condition.id}>Tipo Condizione: {condition.conditionName}</Option>)
            })

            let optionShelfs = [];
            allShelfs.map((shelf,j)=>{
                optionShelfs.push(<Option value={shelf.id} key={shelf.id}>Codice Scaffale: {shelf.shelfCode} / Capacità: {shelf.capacity}</Option>)
            })

            return (

                <LayoutContentWrapper>
                    <PageHeader>{log ? "Modifica Libro" : "Inserisci Libro"}</PageHeader>
                    <LayoutContent>
                        <Form onSubmit={this.handleSubmit}>

                            <FormItem  label="Titolo" {...formItemLayout}>
                                {getFieldDecorator('title', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Titolo obbligatorio',
                                        },
                                    ],
                                    initialValue : title
                                })(<Input  name="title" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Autore" {...formItemLayout}>
                                {getFieldDecorator('author', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Autore obbligatorio',
                                        },
                                    ],
                                    initialValue : author
                                })(<Input  name="author" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem  label="Prezzo € " {...formItemLayout}>
                                <InputNumber
                                    value={price}
                                    min={0}
                                    /*formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\€\s?|(,*)/g, '')}*/
                                    onChange={this.onChangePrice}
                                />
                            </FormItem>

                            <FormItem required label="Numero di Copie" {...formItemLayout}>
                                <InputNumber keyboard={false} min={1} value={numberOfCopy} defaultValue={numberOfCopy} onChange={this.onChangeNofC} />
                            </FormItem>

                            <FormItem  label="Descrizione" {...formItemLayout}>
                                {getFieldDecorator('specialAttributes', {
                                    rules: [
                                    ],
                                    initialValue : specialAttributes
                                })(<Input  name="specialAttributes" onChange={this.handleForm}/>)}
                            </FormItem>

                            <FormItem label="Formato Libro" {...formItemLayout}>
                                <Select value={format} mode="single" style={{ width: '100%' }} placeholder="Formato" showSearch allowClear={"true"} onChange={(e)=>this.handleSelectFormat(e)}>
                                    {optionFormat}
                                </Select>
                            </FormItem>

                            <FormItem required label="Condizioni Libro" {...formItemLayout}>
                                <Select value={condition} mode="single" style={{ width: '100%' }} placeholder="Condizioni Libro" showSearch onChange={(e)=>this.handleSelectCondition(e)}>
                                    {optionCondition}
                                </Select>
                            </FormItem>

                            <FormItem required label="Scaffale" {...formItemLayout}>
                                <Select value={shelf} mode="single" style={{ width: '100%' }} placeholder="Scaffale" showSearch onChange={(e)=>this.handleSelectShelf(e)}>
                                    {optionShelfs}
                                </Select>
                            </FormItem>

                            <FormItem  label= "Foto Libro" {...formItemLayout}>

                                <WebcamCaptureBook saveImg={this.saveImg} />
                                <div style={{display:"flex", maxWidth:"100%", flexWrap:"wrap", marginTop:"20px"}}>
                                    {
                                        imageComponent.map((item,x)=>{
                                             return <div
                                                        key={item.lastModified}
                                                        style={{ display:"flex", flexDirection:"column", alignItems:"center", width: "48%", height:"auto", marginRight:"5px", padding:"5px", border:"1px solid grey" }}
                                                    >
                                                        <img src={ typeof item == "string" ? item : URL.createObjectURL(item)} alt="" width="70%" height="70%" alt=""/>

                                                        <Button style = {{marginTop: "10px", color:"red", borderColor:"red", width:"50%"}} type="dashed" onClick={()=>{this.removeImgComponent(x)}}>-
                                                        </Button>
                                                    </div>
                                        })
                                    }

                                    {
                                        //mi posso creare una card dove visualizzo le singole immagini
                                        //sotto ogni immagine metto un tastino per eliminarla e vado a togliere
                                        //l'immagine in posizione x dall'array di immagini <img src={ typeof item == "string" ? item : URL.createObjectURL(item)} alt="" width="100%" height="70%"/>

                                        imageFile.map((item,x)=>{

                                            return <div
                                                    key={item}
                                                    style={{ display:"flex", flexDirection:"column", alignItems:"center", width: "48%", height:"auto", marginRight:"5px", padding:"5px", border:"1px solid grey"}}
                                                    >
                                                        <GetImageResourceLazy fileNameUri={item}/>

                                                        <Button style = {{marginTop: "10px", color:"red", borderColor:"red", width:"50%"}} type="dashed" onClick={()=>{this.removeImg(x)}}>-
                                                        </Button>
                                                   </div>
                                        })

                                    }
                                </div>
                            </FormItem>







                        </Form>
                                <FormItem style={{marginTop:"10px", marginLeft:"auto", marginRight:"auto", maxWidth:"640px", maxHeight:"320px"}}>
                                    <div style={{ margin: "auto", display: "flex", alignItems: "center", flexDirection: "column"}}>
                                        <div id="scanner-container" style={{display:"flex", justifyContent:"center"}}/>
                                              <div>
                                                <p style={{ display: "inline-block" }}>Barcode: </p>
                                                <Bardcodetextfield isbn={this.saveISBN} style={{ display: "inline-block" }}  send={isbn}/>
                                              </div>
                                              <div style={{marginTop:"10px", width:"50%", marginBottom:"50px"}}>
                                                <Input value={isbn} name="isbn" onChange={this.handleChangeIsbn} allowClear={true}/>
                                              </div>
                                    </div>
                                </FormItem>
                            <Form.Item style={{marginTop:"110px"}}>
                                <Button style = {{marginLeft: "auto", marginRight: "auto", display: "block", borderRadius: "99px", marginTop: "30px"}} type="primary" onClick={this.handleSubmit}>
                                    Salva
                                </Button>
                            </Form.Item>
                    </LayoutContent>
                </LayoutContentWrapper>

            );
        }
    }

}


export default connect(
    state => ({
        getBook: state.Employee.getBook,
        allShelfs: state.Employee.allShelfs,
        conditions: state.Employee.conditions,
        allFormats : state.Employee.allFormats
    }),
    { getBookById, getAllShelfs, getAllConditions, getAllFormats, addNewBook }
)(AddOrUpdateBook);
