import actions from './actions';
import createNotification from "../../components/notification";

const initState = { allSuperAdmin: null, allAdmin: null, getAdmin: null, allLibraries: null, getLibrary: null, allTypes: null, search: null };

export default function authReducer(state = initState, action) {
    switch (action.type) {

        /* GET ALL SUPERADMIN */
        case actions.GETALLSUPERADMIN_OK:
            return {...state, allSuperAdmin: action.allSuperAdmin};
        case actions.GETALLSUPERADMIN_ERROR:
            return {...state, allSuperAdmin:[]}
        /* /GET ALL SUPERADMIN */

        /* ADD/UPDATE SUPERADMIN*/
        case actions.ADDUPDATESUPERADMIN_OK:
            return {...state};
        case actions.ADDUPDATESUPERADMIN_ERROR:
            return {...state};
        /* /ADD/UPDATE SUPERADMIN*/

        /* DELETE SUPERADMIN */
        case actions.DELETESUPERADMIN_OK: {
            return  {...state , allSuperAdmin : state.allSuperAdmin.filter((element) => {if(element.id !== action.data) return element; return null})};}
        case actions.DELETESUPERADMIN_ERROR:
            return {...state};
        /* /DELETE SUPERADMIN */

        /* GET ALL ADMIN */
        case actions.GETALLADMIN_OK:
            return {...state, allAdmin: action.allAdmin};
        case actions.GETALLADMIN_ERROR:
            return {...state, allAdmin: [] };
        /* /GET ALL ADMIN */

        /* GET ADMIN BY ID */
        case actions.GETADMINBYID_OK:
            return {...state, getAdmin: action.getAdmin};
        case actions.GETADMINBYID_ERROR:
            return {...state, getAdmin: []}
        /* /GET ADMIN BY ID */

        /* ADD/UPDATE ADMIN */
        case actions.ADDUPDATEADMIN_OK:
            return {...state};
        case actions.ADDUPDATEADMIN_ERROR:
            return {...state}
        /* /ADD/UPDATE ADMIN */

        /* DELETE ADMIN */
        case actions.DELETEADMIN_OK:
            return {...state, allAdmin : [...state.allAdmin.filter((element) => {if(element.id !== action.delete_id) return element; return null})]};
        case actions.DELETEADMIN_ERROR:
            return {...state};
        /* /DELETE ADMIN */

        /* GET ALL LIBRARIES */
        case actions.GETALLLIBRARIES_OK:
            return {...state, allLibraries: action.allLibraries};
        case actions.GETALLLIBRARIES_ERROR:
            return {...state, allLibraries:[]}
        /* /GET ALL LIBRARIES */

        /* GET LIBRARY BY ID */
        case actions.GETLIBRARYBYID_OK:
            return {...state, getLibrary: action.getLibrary};
        case actions.GETLIBRARYBYID_ERROR:
            return {...state, getLibrary: []};
        /* /GET LIBRARY BY ID */

        /* GET ALL LIBRARY TYPE*/
        case actions.GETALLLIBRARYTYPE_OK:
            return {...state, allTypes: action.allTypes};
        case actions.GETALLLIBRARYTYPE_ERROR:
            return {...state, allTypes: []};
        /* /GET ALL LIBRARY TYPE*/

        /* DELETE LIBRARY */
        case actions.DELETELIBRARY_OK:
            return {...state, allLibraries : [...state.allLibraries.filter((element) => {if(element.id !== action.deleteLibrary_id) return element; return null})]}
        /* /DELETE LIBRARY */

        /* SEARCH */
        case actions.SEARCHLIBRARY_OK:
            return {...state, search: action.search};
        case actions.SEARCHLIBRARY_ERROR:
            return {...state, search: []};
        /* /SEARCH */

        /* GET ALL EMPLOYEES BY LIBRARY */
        case actions.GETALLEMPLOYEESBYLIBRARY_OK :
            return {...state, allEmployeesByLibrary : action.allEmployeesByLibrary};
        case actions.GETALLEMPLOYEESBYLIBRARY_ERROR :
            return {...state, allEmployeesByLibrary: []};
        /* /GET ALL EMPLOYEES BY LIBRARY */

        default:
            return state;
    }
}