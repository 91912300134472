const apiUrl = 'https://librarius.ivert.it';//https://librarius.ivert.it //'http://192.168.3.42:8080'


const siteConfig = {
  siteName: 'Librarius',
  siteIcon: 'ion-flash',
  footerText: '',
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'italian';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

const urlimages= "http://localhost:8080/library/fileserver/image";


const endpoints = {
  restLogin: apiUrl + "/api/auth/login", //localhost:8080/api/auth/login path completo per raggiungere la login del backend springBoot

  /* SUPER ADMIN */

  /* FIND ALL SUPERADMIN */
  findAllSuperadmin : apiUrl + "/api/auth/superadmins",
  /* /FIND ALL SUPERADMIN */

  /* DELETE SUPERADMIN */
  deleteSuperadmin : apiUrl + "/api/auth/superadmindelete",
  /* /DELETE SUPERADMIN */

  /* FIND ALL ADMIN */
  findAllAdmin : apiUrl + "/api/auth/admins",
  /* /FIND ALL ADMIN */

  /* FIND ADMIN BY ID */
  getAdminById : apiUrl + "/api/auth/admin",
  /* /FIND ADMIN BY ID */

  /* ADD/UPDATE SUPERADMIN */
  addNewSuperAdmin : apiUrl + "/api/auth/superadminaddupdate",
  /* /ADD/UPDATE SUPERADMIN */

  /* ADD/UPDATE ADMIN */
  addNewAdmin : apiUrl + "/api/auth/adminaddupdate",
  /* /ADD/UPDATE ADMIN */

  /* DELETE ADMIN */
  deleteAdmin : apiUrl + "/api/auth/admindelete",
  /* /DELETE ADMIN */

  /* GET ALL LIBRARIES */
  getAllLibraries : apiUrl + "/api/libraries",
  /* /GET ALL LIBRARIES */

  /* GET LIBRARY BY ID */
  getLibraryById : apiUrl + "/api/library",
  /* /GET LIBRARY BY ID */

  /* GET ALL LIBRARY TYPE */
  getAllLibraryTypes : apiUrl + "/api/types",
  /* /GET ALL LIBRARY TYPE */

  /* ADD/UPDATE LIBRARY */
  addNewLibrary : apiUrl + "/api/addUpdateLibraries",
  /* /ADD/UPDATE LIBRARY */

  /* DELETE LIBRARY */
  deleteLibrary : apiUrl + "/api/deleteLibrary",
  /* /DELETE LIBRARY */

  /* SEARCH */
  librarySearch : apiUrl + "/api/libraries/search",
  /* /SEARCH */

  /* /SUPER ADMIN */

  /* ADMIN */

  /* GET ALL LIBRARIES BY ADMIN */
  getAllLibrariesByAdmin : apiUrl + "/api/libraries",
  /* /GET ALL LIBRARIES BY ADMIN */

  /* GET ALL EMPLOYEES BY LIBRARY */
  getAllEmployeesByLibrary : apiUrl + "/api/auth/employeesLib",
  /* /GET ALL EMPLOYEES BY LIBRARY */

  /* GET ALL EMPLOYEES */
  getAllEmployees : apiUrl + "/api/auth/employees",
  /* /GET ALL EMPLOYEES */

  /* GET EMPLOYEE BY ID */
  getEmployeeById : apiUrl + "/api/auth/employee",
  /* /GET EMPLOYEE BY ID */

  /* ADD/UPDATE EMPLOYEE */
  addNewEmployee: apiUrl + "/api/auth/employeeaddupdate",
  /* /ADD/UPDATE EMPLOYEE */

  /* /ADMIN */


  /* EMPLOYEE */

  /* GET LIBRARY BY EMPLOYEE */
  getLibraryByEmployee: apiUrl + "/api/libraryEmployee",
  /* /GET LIBRARY BY EMPLOYEE */

  /* GET ALL LITERARY GENRES */
  getAllLiteraryGenres: apiUrl + "/api/literaryGenres",
  /* /GET ALL LITERARY GENRES */

  /* GET ALL SHELFS */
  getAllShelfs: apiUrl + "/api/shelfs",
  /* /GET ALL SHELFS */

  /* GET SHELF BY ID */
  getShelfById: apiUrl + "/api/shelf",
  /* /GET SHELF BY ID */

  /* ADD/UPDATE SHELF */
  addNewShelf: apiUrl + "/api/saveShelf",
  /* /ADD/UPDATE SHELF */

  /* DELETE SHELF */
  deleteShelf: apiUrl + "/api/deleteShelf",
  /* /DELETE SHELF */

  /* GET ALL BOOK CONDITIONS */
  getAllConditions: apiUrl + "/api/conditions",
  /* /GET ALL BOOK CONDITIONS */

  /* GET ALL FORMATS */
  getAllFormats: apiUrl + "/api/formats",
  /* /GET ALL FORMATS */

  /* GET ALL BOOKS BY LIBRARY */
  getAllBooksByLibrary: apiUrl + "/api/books",
  /* /GET ALL BOOKS BY LIBRARY */

  /* ADD/UPDATE BOOK */
  addNewBook: apiUrl + "/api/addUpdateBook",
  getImageResource: apiUrl + "/api/file/getImageResource",
  /* /ADD/UPDATE BOOK */

  /* DELETE BOOK */
  deleteBook: apiUrl + "/api/deleteBook",
  /* /DELETE BOOK */

  /* GET BOOK BY ID */
  getBookById: apiUrl + "/api/book",
  /* /GET BOOK BY ID */

  /* BOOK SEARCH */
  bookSearch: apiUrl + "/api/searchBook",
  /* /BOOK SEARCH */

  /* ALL LOAN */
  allLoan: apiUrl + "/api/loans",
  /* /ALL LOAN */

  /* GET LOAN BY ID */
  getLoanById: apiUrl + "/api/loan",
  /* /GET LOAN BY ID */

  /* ADD/UPDATE LOAN */
  addNewLoan: apiUrl + "/api/addUpdateLoan",
  /* /ADD/UPDATE LOAN */

  /* DELETE LOAN */

  /* /DELETE LOAN */
  deleteLoan: apiUrl + "/api/deleteLoan",
  /* /EMPLOYEE */

  /* DELETE EMPLOYEE */
  deleteEmployee: apiUrl + "/api/auth/deleteEmployee",
  /* /DELETE EMPLOYEE */

  /* BOOK SEARCH */
  loanSearch: apiUrl + "/api/searchLoan",
  /* /BOOK SEARCH */

};

  const roles = {
    superadmin: "ROLE_SUPERADMIN",
    admin: "ROLE_ADMIN",
    employee: "ROLE_EMPLOYEE"
    //user: "ROLE_USER"
  };

export { siteConfig, language, themeConfig, jwtConfig ,urlimages, endpoints , roles};
