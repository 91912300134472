const actions = {

    REQUESTADD_OK:'REQUESTADD_OK',
    REQUESTADD_ERROR:'REQUESTADD_ERROR',

    /* GET LIBRARY BY EMPLOYEE */
    GETLIBRARYBYEMPLOYEE : "GETLIBRARYBYEMPLOYEE",
    GETLIBRARYBYEMPLOYEE_OK : "GETLIBRARYBYEMPLOYEE_OK",
    GETLIBRARYBYEMPLOYEE_ERROR : "GETLIBRARYBYEMPLOYEE_ERROR",

    getLibraryByEmployee: () => ({
        type: actions.GETLIBRARYBYEMPLOYEE
    }),
    /* /GET LIBRARY BY EMPLOYEE */

    /* GET ALL LITERARY GENRES */
    GETALLLITERARYGENRES : "GETALLLITERARYGENRES",
    GETALLLITERARYGENRES_OK : "GETALLLITERARYGENRES_OK",
    GETALLLITERARYGENRES_ERROR : "GETALLLITERARYGENRES_ERROR",

    getAllLiteraryGenres : () => ({
        type: actions.GETALLLITERARYGENRES
    }),
    /* /GET ALL LITERARY GENRES */

    /* GET ALL SHELFS */
    GETALLSHELFS : "GETALLSHELFS",
    GETALLSHELFS_OK : "GETALLSHELFS_OK",
    GETALLSHELFS_ERROR : "GETALLSHELFS_ERROR",

    getAllShelfs : () => ({
        type: actions.GETALLSHELFS
    }),
    /* /GET ALL SHELFS */

    /* GET SHELF BY ID */
    GETSHELFBYID : "GETSHELFBYID",
    GETSHELFBYID_OK : "GETSHELFBYID_OK",
    GETSHELFBYID_ERROR : "GETSHELFBYID_ERROR",

    getShelfById : (data) => ({
        type: actions.GETSHELFBYID,
        payload: data
    }),
    /* /GET SHELF BY ID */

    /* ADD/UPDATE SHELF */
    ADDUPDATESHELF : "ADDUPDATESHELF",
    ADDUPDATESHELF_OK : "ADDUPDATESHELF_OK",
    ADDUPDATESHELF_ERROR : "ADDUPDATESHELF_ERROR",

    addNewShelf : (data) => ({
        type: actions.ADDUPDATESHELF,
        payload: data
    }),
    /* /ADD/UPDATE SHELF */

    /* DELETE SHELF */
    DELETESHELF : "DELETESHELF",
    DELETESHELF_OK : "DELETESHELF_OK",
    DELETESHELF_ERROR : "DELETESHELF_ERROR",

    deleteShelf: (data) => ({
        type: actions.DELETESHELF,
        payload: data
    }),
    /* /DELETE SHELF */

    /* GET ALL CONDITIONS */
    GETALLCONDITIONS : "GETALLCONDITIONS",
    GETALLCONDITIONS_OK : "GETALLCONDITIONS_OK",
    GETALLCONDITIONS_ERROR : "GETALLCONDITIONS_ERROR",

    getAllConditions : ()=>({
        type: actions.GETALLCONDITIONS
    }),
    /* /GET ALL CONDITIONS */

    /* GET ALL FORMATS */
    GETALLFORMATS : "GETALLFORMATS",
    GETALLFORMATS_OK : "GETALLFORMATS_OK",
    GETALLFORMATS_ERROR : "GETALLFORMATS_ERROR",

    getAllFormats : ()=>({
        type: actions.GETALLFORMATS

    }),
    /* /GET ALL FORMATS */

    /* GET ALL BOOKS BY LIBRARY */
    GETALLBOOKSBYLIBRARY : "GETALLBOOKSBYLIBRARY",
    GETALLBOOKSBYLIBRARY_OK : "GETALLBOOKSBYLIBRARY_OK",
    GETALLBOOKSBYLIBRARY_ERROR : "GETALLBOOKSBYLIBRARY_ERROR",

    getAllBooksByLibrary: () => ({
        type: actions.GETALLBOOKSBYLIBRARY
    }),
    /* /GET ALL BOOKS BY LIBRARY */

    /* ADD/UPDATE BOOK */
    ADDUPDATEBOOK : "ADDUPDATEBOOK",
    ADDUPDATEBOOK_OK : "ADDUPDATEBOOK_OK",
    ADDUPDATEBOOK_ERROR : "ADDUPDATEBOOK_ERROR",
    ADDIMAGESONSERVER : "ADDIMAGESONSERVER",

    addImagesOnServer: (data,imagestoadd)=>({
            type: actions.ADDIMAGESONSERVER,
            payload: data,
            imagestoadd : imagestoadd
    }),

    addNewBook : (data) => ({
        type: actions.ADDUPDATEBOOK,
        payload: data
    }),
    /* /ADD/UPDATE BOOK */

    /* GET BOOK BY ID */
    GETBOOKBYID : "GETBOOKBYID",
    GETBOOKBYID_OK : "GETBOOKBYID_OK",
    GETBOOKBYID_ERROR : "GETBOOKBYID_ERROR",

    getBookById : (data) => ({
        type: actions.GETBOOKBYID,
        payload: data
    }),
    /* /GET BOOK BY ID */

    /* SEARCH BOOK */
    BOOKSEARCH : "BOOKSEARCH",
    BOOKSEARCH_OK : "BOOKSEARCH_OK",
    BOOKSEARCH_ERROR : "BOOKSEARCH_ERROR",

    bookSearch : (data) => ({
        type: actions.BOOKSEARCH,
        payload: data
    }),
    /* /SEARCH BOOK */

    /* DELETE BOOK */
    DELETEBOOK : "DELETEBOOK",
    DELETEBOOK_OK : "DELETEBOOK_OK",
    DELETEBOOK_ERROR : "DELETEBOOK_ERROR",

    deleteBook: (data) => ({
        type: actions.DELETEBOOK,
        payload: data
    }),
    /* /DELETE BOOK */

    /* ALL LOAN */
    ALLLOAN : "ALLLOAN",
    ALLLOAN_OK : "ALLLOAN_OK",
    ALLLOAN_ERROR : "ALLLOAN_ERROR",

    getAllLoan : () => ({
        type: actions.ALLLOAN
    }),
    /* /ALL LOAN */

    /* GET LOAN BY ID */
    GETLOANBYID : "GETLOANBYID",
    GETLOANBYID_OK : "GETLOANBYID_OK",
    GETLOANbYID_ERROR : "GETLOANBYID_ERROR",

    getLoanById : (data) => ({
        type: actions.GETLOANBYID,
        payload: data
    }),
    /* /GET LOAN BY ID */

    /* ADD/UPDATE NEW LOAN */
    ADDUPDATENEWLOAN: "ADDUPDATENEWLOAN",
    ADDUPDATENEWLOAN_OK: "ADDUPDATENEWLOAN_OK",
    ADDUPDATENEWLOAN_ERROR: "ADDUPDATENEWLOAN_ERROR",

    addNewLoan: (data) => ({
        type: actions.ADDUPDATENEWLOAN,
        payload: data
    }),
    /* /ADD/UPDATE NEW LOAN */

    /* DELETE LOAN */
    DELETELOAN : "DELETELOAN",
    DELETELOAN_OK : "DELETELOAN_OK",
    DELETELOAN_ERROR : "DELETELOAN_ERROR",

    deleteLoan: (data) => ({
        type: actions.DELETELOAN,
        payload: data
    }),
    /* /DELETE LOAN */

    /* SEARCH BOOK */
    LOANSEARCH : "LOANSEARCH",
    LOANSEARCH_OK : "LOANSEARCH_OK",
    LOANSEARCH_ERROR : "LOANSEARCH_ERROR",

    loanSearch : (data) => ({
        type: actions.LOANSEARCH,
        payload: data
    })
    /* /SEARCH BOOK */
};
export default actions;