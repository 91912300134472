import { all,call, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import SuperFetch from '../../helpers/superFetch'
import {endpoints} from '../../settings/index'
import {goBack} from "react-router-redux";


export function* getLibraryByEmployee(){
    yield takeEvery(actions.GETLIBRARYBYEMPLOYEE, function*(data){
        const {response, error} = yield call( SuperFetch.get, endpoints.getLibraryByEmployee,data.payload, true);
        if(response){
            yield put({
                type: actions.GETLIBRARYBYEMPLOYEE_OK,
                library: response.value
            })
        }else{
            yield put({
                type: actions.GETLIBRARYBYEMPLOYEE_ERROR,
                error: error
            })
        }
    })
}

export function* getAllLiteraryGenres(){
    yield takeEvery(actions.GETALLLITERARYGENRES,function*(data){
        const { response,error } = yield call( SuperFetch.get, endpoints.getAllLiteraryGenres, data.payload,true);
        if(response){
            yield put({
                type: actions.GETALLLITERARYGENRES_OK,
                allLireraryGenres: response.value
            })
        }else{
            yield put({
                type: actions.GETALLLITERARYGENRES_ERROR,
                error: error
            })
        }
    })
}

export function* getAllShelfs(){
    yield takeEvery(actions.GETALLSHELFS,function*(data){
        const { response, error } = yield call( SuperFetch.get, endpoints.getAllShelfs, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLSHELFS_OK,
                allShelfs: response.value
            })
        }else{
            yield put({
                type: actions.GETALLSHELFS_ERROR,
                error: error
            })
        }
    })
}

export function* getShelfById(){
    yield takeEvery(actions.GETSHELFBYID, function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.getShelfById, data.payload, true);
        if(response){
            yield put({
                type: actions.GETSHELFBYID_OK,
                shelf: response.value
            })
        }else{
            yield put({
                type: actions.GETSHELFBYID_ERROR,
                error: error
            })
        }
    })
}

export function* addNewShelf(){
    yield takeEvery(actions.ADDUPDATESHELF, function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.addNewShelf, data.payload, true);
        if(response){
            yield put({
                type: actions.ADDUPDATESHELF_OK,
                data: response.value
            })
            yield put (goBack())
        }else{
            yield put({
                type: actions.ADDUPDATESHELF_ERROR,
                error: error
            })
        }
    })
}

export function* deleteShelf(){
    yield takeEvery(actions.DELETESHELF, function*(data){
        const {response, error} = yield call( SuperFetch.delete, endpoints.deleteShelf, data.payload, true);
        if(response){
            yield put({
                type: actions.DELETESHELF_OK,
                delete_shelf: data.payload.id
            })
        }else{
            yield put({
                type: actions.DELETESHELF_ERROR,
                error: error
            })
        }
    })
}

export function* getAllConditions(){
    yield takeEvery(actions.GETALLCONDITIONS, function*(data){
        const { response, error } = yield call( SuperFetch.get, endpoints.getAllConditions, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLCONDITIONS_OK,
                conditions: response.value
            })
        }else{
            yield put({
                type: actions.GETALLCONDITIONS_ERROR,
                error: error
            })
        }
    })
}

export function* getAllFormats(){
    yield takeEvery(actions.GETALLFORMATS, function*(data){
        const { response, error } = yield call( SuperFetch.get, endpoints.getAllFormats, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLFORMATS_OK,
                allFormats: response.value
            })
        }else{
            yield put({
                type: actions.GETALLFORMATS_ERROR,
                error: error
            })
        }
    })
}

export function* getAllBooksByLibrary(){
    yield takeEvery(actions.GETALLBOOKSBYLIBRARY, function*(data){
        const { response, error } = yield call( SuperFetch.get, endpoints.getAllBooksByLibrary, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLBOOKSBYLIBRARY_OK,
                books: response.value
            })
        }else{
            yield put({
                type: actions.GETALLBOOKSBYLIBRARY_ERROR,
                error: error
            })
        }
    })
}
export function* addImagesOnServer() {
    yield takeEvery(actions.ADDIMAGESONSERVER, function* (data) {
        const {response,error}= yield call(SuperFetch.post,endpoints.addimagesonserver,data.imagestoadd,true,true);
                if(response) {
            let arraypath = response.value.map(element => element.path);

            data.payload.imageFile = [ ...data.payload.imageFile,...arraypath];
            yield put({
                type: actions.ADDUPDATEBOOK,
                payload: data.payload,
            })
        }else{
            yield put({
                type: actions.REQUESTADD_ERROR,
                error: error,
            })
        }
    })
}

export function* addNewBook(){
    yield takeEvery(actions.ADDUPDATEBOOK, function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.addNewBook, data.payload, true, true);
        if(response){
            yield put({
                type: actions.ADDUPDATEBOOK_OK,
                data: response.value
            })
            yield put (goBack())
        }else{
            yield put({
                type: actions.ADDUPDATEBOOK_OK,
                error: error
            })
            yield put (goBack())
        }
    })
}

/*export function* addImagesOnServer() {
    yield takeEvery(actions.ADDIMAGESONSERVER, function* (data) {

        const {response,error}= yield call(SuperFetch.post,endpoints.addimagesonserver,data.imagestoadd,true,true);
                if(response) {
            let arraypath = response.value.map(element => element.path);

            data.payload.imageFile = [ ...data.payload.imageFile,...arraypath];
            yield put({
                type: actions.ADDDOGS,
                payload: data.payload,
            })
        }else{
            yield put({
                type: actions.REQUESTADD_ERROR,
                error: error,
            })
        }
    })
}*/

export function* getBookById(){
    yield takeEvery(actions.GETBOOKBYID,function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.getBookById, data.payload, true);
        if(response){
            yield put({
                type: actions.GETBOOKBYID_OK,
                getBook : response.value
            })
        }else{
            yield put({
                type: actions.GETBOOKBYID_ERROR,
                error: error
            })
        }
    })
}

export function* bookSearch(){
    yield takeEvery(actions.BOOKSEARCH,function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.bookSearch, data.payload, true);
        if(response){
            yield put({
                type: actions.BOOKSEARCH_OK,
                BS: response.value
            })
        }else{
            yield put({
                type: actions.BOOKSEARCH_ERROR,
                error: error
            })
        }
    })
}

export function* deleteBook(){
    yield takeEvery(actions.DELETEBOOK, function*(data){
        const { response, error } = yield call(SuperFetch.post, endpoints.deleteBook, data.payload, true);
        if(response){
            yield put({
                type: actions.DELETEBOOK_OK,
                delete_book: data.payload.id
            })
//            window.location.reload();
        }else{
            yield put({
                type: actions.DELETEBOOK_ERROR,
                error: error
            })
        }
    })
}

export function* getAllLoan(){
    yield takeEvery(actions.ALLLOAN,function*(data){
        const { response, error } = yield call(SuperFetch.get, endpoints.allLoan, data.payload, true);
        if(response){
            yield put({
                type: actions.ALLLOAN_OK,
                allLoan: response.value
            })
        }else{
            yield put({
                type: actions.ALLLOAN_ERROR,
                error: error
            })
        }
    })
}

export function* getLoanById(){
    yield takeEvery(actions.GETLOANBYID, function*(data){
        const { response, error } = yield call(SuperFetch.post, endpoints.getLoanById, data.payload, true);
        if(response){
            yield put({
                type: actions.GETLOANBYID_OK,
                getLoan: response.value
            })
        }else{
            yield put({
                type: actions.GETLOANBYID_ERROR,
                error: error
            })
        }
    })
}

export function* addNewLoan(){
    yield takeEvery(actions.ADDUPDATENEWLOAN, function*(data){
        const { response, error } = yield call(SuperFetch.post, endpoints.addNewLoan, data.payload,  true, true);
        if(response){
            yield put({
                type: actions.ADDUPDATENEWLOAN_OK,
                data: response.value
            })
            yield put(goBack())
        }else{
            yield put({
                type: actions.ADDUPDATENEWLOAN_ERROR
            })
        }
    })
}

export function* deleteLoan(){
    yield takeEvery(actions.DELETELOAN, function*(data){
        const {response, error} = yield call(SuperFetch.delete, endpoints.deleteLoan, data.payload, true);
        if(response){
            yield put({
                type: actions.DELETELOAN_OK,
                delete_loan : data.payload.id
            })
        }else{
            yield put({
                type: actions.DELETELOAN_ERROR,
                error: error
            })
        }
    })
}

export function* loanSearch(){
    yield takeEvery(actions.LOANSEARCH,function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.loanSearch, data.payload, true);
        if(response){
            yield put({
                type: actions.LOANSEARCH_OK,
                LS: response.value
            })
        }else{
            yield put({
                type: actions.LOANSEARCH_ERROR,
                error: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getLibraryByEmployee),
        fork(getAllLiteraryGenres),
        fork(getAllShelfs),
        fork(getShelfById),
        fork(addNewShelf),
        fork(getAllConditions),
        fork(getAllFormats),
        fork(getAllBooksByLibrary),
        fork(addNewBook),
        fork(getBookById),
        fork(bookSearch),
        fork(getAllLoan),
        fork(deleteBook),
        fork(getLoanById),
        fork(addNewLoan),
        fork(deleteLoan),
        fork(deleteShelf),
        fork(loanSearch)
    ]);
}