import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import SuperFetch from '../../helpers/superFetch'
import {goBack} from "react-router-redux";
import {endpoints} from '../../settings/index'

export function* getAllLibrariesByAdmin(){
    yield takeEvery(actions.GETALLLIBRARIESBYADMIN, function*(data){
        const { response, error } = yield call( SuperFetch.get, endpoints.getAllLibrariesByAdmin, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLLIBRARIESBYADMIN_OK,
                libraries : response.value
            })
        }else{
            yield put({
                type: actions.GETALLLIBRARIESBYADMIN_ERROR,
                error: error
            })
        }
    })
}

export function* getAllEmployees(){
    yield takeEvery(actions.GETALLEMPLOYEES, function*(data){
        const { response, error } = yield call( SuperFetch.get, endpoints.getAllEmployees, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLEMPLOYEES_OK,
                allEmployees : response.value
            })
        }else{
            yield put({
                type: actions.GETALLEMPLOYEES_ERROR,
                error: error
            })
        }
    })
}

export function* getEmployeeById(){
    yield takeEvery(actions.GETEMPLOYEEBYID, function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.getEmployeeById, data.payload, true);
        if(response){
            yield put({
                type: actions.GETEMPLOYEEBYID_OK,
                employee: response.value
            })
        }else{
            yield put({
                type: actions.GETEMPLOYEEBYID_ERROR,
                error: error
            })
        }
    })
}

export function* addNewEmployee(){
    yield takeEvery(actions.ADDUPDATEEMPLOYEE, function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.addNewEmployee, data.payload,true);
        if(response){
            yield put({
                type: actions.ADDUPDATEEMPLOYEE_OK,
                data: response.value
            })
            yield put (goBack())
        }else{
            yield put({
                type: actions.ADDUPDATEEMPLOYEE_ERROR,
                error: error
            })
        }
    })
}

export function* deleteEmployee(){
    yield takeEvery(actions.DELETEEMPLOYEE, function*(data){
        const {response, error} = yield call(SuperFetch.delete, endpoints.deleteEmployee, data.payload, true);
        if(response){
            yield put({
                type: actions.DELETEEMPLOYEE_OK,
                delete_employee: data.payload.id
            })
        }else{
            yield put({
                type: actions.DELETEEMPLOYEE_ERROR,
                error: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllLibrariesByAdmin),
        fork(getAllEmployees),
        fork(getEmployeeById),
        fork(addNewEmployee),
        fork(deleteEmployee)
        //fork(getAllCityAutocompleteAdmin)
    ]);
}


