import { all,call, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import SuperFetch from '../../helpers/superFetch'
import {endpoints} from '../../settings/index'
import {goBack} from "react-router-redux";
//import { useHistory } from 'react-router';
//
//const history = useHistory()

export function* getAllSuperadmin(){
    yield takeEvery(actions.GETALLSUPERADMIN, function*(data){
        const {response,error} = yield call( SuperFetch.get, endpoints.findAllSuperadmin, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLSUPERADMIN_OK,
                allSuperAdmin: response.value
            })
        }else{
            yield put({
                type: actions.GETALLSUPERADMIN_ERROR,
                error: error
            })
        }

    })

}

export function* addNewSuperAdmin(){
    yield takeEvery(actions.ADDUPDATESUPERADMIN, function*(data){
        const {response,error} = yield call( SuperFetch.post, endpoints.addNewSuperAdmin, data.payload, true);
        if(response){
            yield put({
                type: actions.ADDUPDATESUPERADMIN_OK,
                data: response.value
            })
            yield put (goBack())
        }else{
            yield put({
                type: actions.ADDUPDATESUPERADMIN_ERROR,
                error: error
            })
        }

    })

}

export function* deleteSuperadmin(){
    yield takeEvery(actions.DELETESUPERADMIN, function*(data){
        const {response,error} = yield call( SuperFetch.post, endpoints.deleteSuperadmin, data.payload, true);
        if(response){
            yield put({
                type: actions.DELETESUPERADMIN_OK,
                delete: data.payload.id

            })
        }else{
            yield put({
                type: actions.DELETESUPERADMIN_ERROR,
                error: error
            })
        }

    })

}

export function* getAllAdmin(){
    yield takeEvery(actions.GETALLADMIN, function*(data){
        const {response,error} = yield call( SuperFetch.get, endpoints.findAllAdmin, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLADMIN_OK,
                allAdmin: response.value
            })
        }else{
            yield put({
                type: actions.GETALLADMIN_ERROR,
                error: error
            })
        }

    })

}

export function* getAdminById(){
    yield takeEvery(actions.GETADMINBYID, function*(data){
        const {response,error} = yield call( SuperFetch.post, endpoints.getAdminById, data.payload, true);
        if(response){
            yield put({
                type: actions.GETADMINBYID_OK,
                getAdmin: response.value
            })
        }else{
            yield put({
                type: actions.GETADMINBYID_ERROR,
                error: error
            })
        }

    })

}

export function* addNewAdmin(){
    yield takeEvery(actions.ADDUPDATEADMIN, function*(data){

        const {response,error} = yield call( SuperFetch.post, endpoints.addNewAdmin, data.payload, true);
        if(response){
            yield put({
                type: actions.ADDUPDATEADMIN_OK,
                data: response.value
            })
            yield put (goBack())
        }else{
            yield put({
                type: actions.ADDUPDATEADMIN_ERROR,
                error: error
            })
        }

    })

}

export function* deleteAdmin(){
    yield takeEvery(actions.DELETEADMIN,function*(data){
        const {response,error} = yield call( SuperFetch.post, endpoints.deleteAdmin, data.payload, true);
        if(response){
            yield put({
                type: actions.DELETEADMIN_OK,
                delete_id: data.payload.id
            })
        }else{
            yield put({
                type: actions.DELETEADMIN_ERROR,
                error: error
            })
        }
    })
}

export function* getAllLibraries(){
    yield takeEvery(actions.GETALLLIBRARIES, function*(data){
        const {response,error} = yield call(SuperFetch.get, endpoints.getAllLibraries, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLLIBRARIES_OK,
                allLibraries: response.value
            })
        }else{
            yield put({
                type: actions.GETALLLIBRARIES_ERROR,
                error: error
            })
        }
    })
}

export function* getLibraryById(){
    yield takeEvery(actions.GETLIBRARYBYID, function*(data){
        const {response,error} = yield call( SuperFetch.post, endpoints.getLibraryById, data.payload, true);
        if(response){
            yield put({
                type: actions.GETLIBRARYBYID_OK,
                getLibrary: response.value
            })
        }else{
            yield put({
                type: actions.GETLIBRARYBYID_ERROR,
                error: error
            })
        }

    })

}

export function* getAllLibraryTypes(){
    yield takeEvery(actions.GETALLLIBRARYTYPE, function*(data){
        const {response,error} = yield call(SuperFetch.get, endpoints.getAllLibraryTypes, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLLIBRARYTYPE_OK,
                allTypes: response.value
            })
        }else{
            yield put({
                type: actions.GETALLLIBRARYTYPE_ERROR,
                error: error
            })
        }
    })
}

export function* addNewLibrary(){
    yield takeEvery(actions.ADDUPDATELIBRARY, function*(data){
        const {response,error} = yield call( SuperFetch.post, endpoints.addNewLibrary, data.payload, true);
        if(response){
            yield put({
                type: actions.ADDUPDATELIBRARY_OK,
                data: response.value
            })
            yield put (goBack())
        }else{
            yield put({
                type: actions.ADDUPDATELIBRARY_ERROR,
                error: error
            })
        }

    })

}

export function* deleteLibrary(){
    yield takeEvery(actions.DELETELIBRARY, function*(data){
        const {response, error} = yield call(SuperFetch.delete, endpoints.deleteLibrary, data.payload, true);
        if(response){
            yield put({
                type: actions.DELETELIBRARY_OK,
                deleteLibrary_id : data.payload.id
            })
        }else{
            yield put({
                type: actions.DELETELIBRARY_ERROR,
                error: error
            })
        }
    })
}

export function* librarySearch(){
    yield takeEvery(actions.SEARCHLIBRARY, function*(data){
        const {response,error} = yield call(SuperFetch.post, endpoints.librarySearch, data.payload, true);
        if(response){
            yield put({
                type: actions.SEARCHLIBRARY_OK,
                search: response.value
            })
        }else{
            yield put({
                type: actions.SEARCHLIBRARY_ERROR,
                error: error
            })
        }
    })
}

export function* getAllEmployeesByLibrary(){
    yield takeEvery(actions.GETALLEMPLOYEESBYLIBRARY, function*(data){
        const { response, error } = yield call( SuperFetch.post, endpoints.getAllEmployeesByLibrary, data.payload, true);
        if(response){
            yield put({
                type: actions.GETALLEMPLOYEESBYLIBRARY_OK,
                allEmployeesByLibrary : response.value
            })
        }else{
            yield put({
                type: actions.GETALLEMPLOYEESBYLIBRARY_ERROR,
                error: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllSuperadmin),
        fork(addNewSuperAdmin),
        fork(deleteSuperadmin),
        fork(getAllAdmin),
        fork(getAdminById),
        fork(addNewAdmin),
        fork(deleteAdmin),
        fork(getAllLibraries),
        fork(getLibraryById),
        fork(getAllLibraryTypes),
        fork(addNewLibrary),
        fork(librarySearch),
        fork(getAllEmployeesByLibrary),
        fork(deleteLibrary)
        //fork(getallsuperadmin),
    ]);
}