import actions from './actions';
const initState = { idToken: null ,libraries: null, allEmployees: null, employee: null};

export default function authReducer(state = initState, action) {
    switch (action.type) {

    /* GET ALL LIBRARIES BY ADMIN */
    case actions.GETALLLIBRARIESBYADMIN_OK :
        return {...state, libraries : action.libraries};
    case actions.GETALLLIBRARIESBYADMIN_ERROR :
        return {...state, libraries : []}
    /* /GET ALL LIBRARIES BY ADMIN */

    /* GET ALL EMPLOYEES */
    case actions.GETALLEMPLOYEES_OK :
        return {...state, allEmployees : action.allEmployees};
    case actions.GETALLEMPLOYEES_ERROR :
        return {...state, allEmployees: []};
    /* /GET ALL EMPLOYEES */

    /* GET EMPLOYEE BY ID */
    case actions.GETEMPLOYEEBYID_OK :
        return {...state, employee : action.employee};
    case actions.GETEMPLOYEEBYID_ERROR :
        return {...state, employee : []}
    /* /GET EMPLOYEE BY ID */

    /* DELETE EMPLOYEE */
    case actions.DELETEEMPLOYEE_OK:
        return {...state, allEmployees : [...state.allEmployees.filter((element) => {if(element.id !== action.delete_employee) return element; return null})]}
    /* /DELETE EMPLOYEE */

        default:
            return state;
    }
}